import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MixedMethodWarningModalComponent, SpecIdInfoModalComponent } from '@app/etfs-equities/components';
import { CostBasisLotErrorModalComponent } from '@app/etfs-equities/components/cost-basis-lot-error-modal/cost-basis-lot-error-modal.component';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { OrderEnums } from '@app/etfs-equities/enums';
import { Account, CostBasis, JsonContent, Order, Quote, TradeTicketForm } from '@app/etfs-equities/models';
import { NoChangesErrorModalComponent } from '@app/etfs-equities/pages/trade/modals/change-order/no-changes-error-modal/no-changes-error-modal.component';
import { CostBasisService, TradeTicketService, WindowService } from '@app/etfs-equities/services';
import {
  createLoadLotsAction,
  createUpdateLotFormAction,
  createValidateOrderAction,
  createValidateOrderSuccessAction,
  selectAccountIsMargin,
  selectCostBasisLots,
  selectCostBasisLotsDate,
  selectCostBasisState,
  selectIsChangeOrderState,
  selectIsEditCostBasisState,
  selectIsScreenSmall,
  selectIsScreenXSmall,
  selectLongTermCostBasisLots,
  selectLotForm,
  selectOrigOrder,
  selectPositionForCurrentQuoteBySecurityType,
  selectQuote,
  selectSelectedAccount,
  selectShortTermCostBasisLots,
  selectTradeTicket,
  TayneState,
} from '@app/etfs-equities/store';
import { CostBasisUtil } from '@app/etfs-equities/utils';
import { createNumberOfSharesSelectedValidator } from '@app/etfs-equities/validators';
import content from '@content/content.json';
import { environment } from '@env/environment';
import { AcceptedRulesNextStep } from '@etfs-equities/enums/triggered-rule.enums';
import { EditService } from '@etfs-equities/services/edit/edit.service';
import { selectClientId } from '@etfs-equities/store/selectors/client-data/client-data.selectors';
import { select, Store } from '@ngrx/store';
import {
  FormStateService,
  RehydratableFormGroup,
  SingleErrorFormControl,
} from '@vanguard/trade-standard-forms-lib-ng-17';
import { CostBasisMethod, HoldingTypeEnum, TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { AnyMaskedOptions } from 'imask';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, first, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'twe-select-shares',
  templateUrl: './select-shares.component.html',
  styleUrls: ['./select-shares.component.scss'],
})
export class SelectSharesPageComponent implements OnInit, OnDestroy {
  AcceptedRulesNextStep = AcceptedRulesNextStep;

  inputIMaskConfig: AnyMaskedOptions = {
    mask: Number,
    signed: false,
    scale: 4,
    padFractionalZeros: true,
    radix: '.',
    thousandsSeparator: ',',
  };

  quantityIMaskConfig: AnyMaskedOptions = {
    mask: Number,
    signed: true,
    scale: 4,
    padFractionalZeros: true,
    radix: '.',
    thousandsSeparator: ',',
  };

  //  Decorators...

  @ViewChild(NoChangesErrorModalComponent)
  noChangesErrorModal: NoChangesErrorModalComponent | undefined;

  @ViewChild(CostBasisLotErrorModalComponent)
  costBasisLotLoadErrorModal: CostBasisLotErrorModalComponent | undefined;

  @ViewChild('specIdInfoModal')
  specIdInfoModal: SpecIdInfoModalComponent | undefined;

  @ViewChild('mixedMethodWarningModal')
  mixedMethodWarningModal: MixedMethodWarningModalComponent | undefined;

  //  Public variables...

  hasInvalidSelectedQtyShares = false;
  content: JsonContent = content;
  formGroups: RehydratableFormGroup[];
  protected readonly timestampSizeEnum = TimestampSize;

  //  Public observables/subjects...

  selectedAccount$: Observable<Account.Account>;
  shortTermLots$: Observable<CostBasis.Lot[] | void>;
  longTermLots$: Observable<CostBasis.Lot[] | void>;
  unsubscribe$ = new Subject<void>();
  tradeTicket$: Observable<TradeTicketForm>;
  origOrder$: Observable<Order.Order>;
  quote$: Observable<Quote>;
  isScreenXSmall$: Observable<boolean>;
  isScreenSmall$: Observable<boolean>;
  lotsDate$: Observable<string>;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly tradeTicketService: TradeTicketService,
    private readonly editService: EditService,
    private readonly costBasisService: CostBasisService,
    private readonly formStateService: FormStateService,
    private readonly windowService: WindowService
  ) {}

  ngOnInit() {
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.quote$ = this.store.pipe(select(selectQuote));
    this.tradeTicket$ = this.store.pipe(select(selectTradeTicket));
    this.origOrder$ = this.store.pipe(select(selectOrigOrder));
    this.isScreenXSmall$ = this.store.pipe(select(selectIsScreenXSmall));
    this.isScreenSmall$ = this.store.pipe(select(selectIsScreenSmall));
    this.lotsDate$ = this.store.pipe(select(selectCostBasisLotsDate));
    this.shortTermLots$ = this.store.pipe(
      select(selectShortTermCostBasisLots),
      withLatestFrom(this.tradeTicket$),
      map(([costBasisLots, tradeTicket]) =>
        this.buildLotTableSelectionData(costBasisLots, tradeTicket, HoldingTypeEnum.SHORT_TERM)
      ),
      takeUntil(this.unsubscribe$)
    );
    this.longTermLots$ = this.store.pipe(
      select(selectLongTermCostBasisLots),
      withLatestFrom(this.tradeTicket$),
      map(([costBasisLots, tradeTicket]) =>
        this.buildLotTableSelectionData(costBasisLots, tradeTicket, HoldingTypeEnum.LONG_TERM)
      ),
      takeUntil(this.unsubscribe$)
    );

    this.watchForCostBasisLotLoadError();
    this.watchForCostBasisLots();
    this.watchForOriginOrder();
    this.populateFormForChangeOrder();
  }

  ngOnDestroy() {
    if (this.formGroups) {
      const selectedLots = CostBasisUtil.makeLotsFormLotsGroups(this.formGroups);
      this.store.dispatch(createUpdateLotFormAction(selectedLots));
    }

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.costBasisService.isLoadingLots = false;
  }

  edit() {
    this.editService.editOrder$.next();
  }

  preview() {
    combineLatest([
      this.store.select(selectIsChangeOrderState),
      this.origOrder$,
      this.store.select(selectCostBasisLots),
      this.store.select(selectIsEditCostBasisState),
      this.store.select(selectAccountIsMargin),
      this.store.select(selectClientId),
    ])
      .pipe(
        take(1),
        tap(([isChangeOrder, order, lots, isEditCostBasis, isMarginAccount, clientId]) => {
          const selectedLots = CostBasisUtil.makeSelectedLotsFromLotFormGroups(lots, this.formGroups);
          if (!isChangeOrder) {
            this.store.dispatch(createValidateOrderAction(false, selectedLots));
          } else {
            const isSelectSharesChanged = !this.tradeTicketService.isOrderEqualSelectSharesForm(
              order,
              lots,
              this.formGroups
            );
            const isTradeTicketChanged = isEditCostBasis
              ? !this.tradeTicketService.isOrderEqualTradeTicketFormCostBasis(order)
              : !this.tradeTicketService.isOrderEqualTradeTicketForm(order);
            if (!isSelectSharesChanged && !isTradeTicketChanged) {
              setTimeout(() => {
                this.noChangesErrorModal.modal.openModalDialog();
              }, 0);
            } else {
              if (isEditCostBasis) {
                // for edit cost basis, there is no validation -- transition directly to preview page
                this.store.dispatch(
                  createValidateOrderSuccessAction({
                    ...order,
                    validated: true,
                    isMarginAccount,
                    clientId,
                    costBasisMethod: CostBasisMethod.SPEC_ID,
                  })
                );
              } else {
                this.store.dispatch(createValidateOrderAction(true, selectedLots));
              }
            }
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  buildForm(costBasisLots: CostBasis.Lot[]) {
    this.formGroups = costBasisLots.map((lot) => {
      // build form for each lot
      const formGroup = new RehydratableFormGroup(lot.lotID, this.formStateService, {
        numberOfSharesSelected: new SingleErrorFormControl('', {
          nonNullable: true,
          updateOn: 'change',
        }),
        selectAllShares: new SingleErrorFormControl(false, {
          nonNullable: true,
          updateOn: 'change',
        }),
      });

      // add validators to form
      formGroup.controls.numberOfSharesSelected.setValidators(() =>
        createNumberOfSharesSelectedValidator(formGroup, lot.quantity)
      );

      // watch for form changes
      formGroup.valueChanges
        .pipe(
          withLatestFrom(this.tradeTicket$),
          tap(([_formValue, tradeTicket]) => this.validateFormGroups(tradeTicket.amount)),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();

      return formGroup;
    });
  }

  getSelectedLotsQty(): number {
    const sum =
      this.formGroups?.reduce((acc, next) => {
        const scrub = CostBasisUtil.formatLotShares(next.controls.numberOfSharesSelected.value);
        return (acc += scrub);
      }, 0) || 0;

    return parseFloat(sum.toFixed(4));
  }

  formGroupsHasError(): boolean {
    let hasError = false;

    this.formGroups?.forEach((formGroup) => {
      if (!formGroup.valid) hasError = true;
    });

    return hasError;
  }

  validateFormGroups(requiredQty: string | number) {
    const selectedQty = this.getSelectedLotsQty();
    this.hasInvalidSelectedQtyShares =
      this.formGroupsHasError() || selectedQty > +requiredQty || selectedQty < +requiredQty;
  }

  calculateEstimatedProceeds(tradeTicket: TradeTicketForm, lot: CostBasis.Lot) {
    if (!tradeTicket) {
      return null;
    }
    switch (tradeTicket.orderType) {
      case OrderEnums.Types.LIMIT:
      case OrderEnums.Types.STOP_LIMIT:
        return parseFloat(tradeTicket.limitPrice) * lot.quantity;
      case OrderEnums.Types.STOP:
        return parseFloat(tradeTicket.stopPrice) * lot.quantity;
      default:
        return lot.marketValue;
    }
  }

  calculateEstimatedGainLoss(tradeTicket: TradeTicketForm, lot: CostBasis.Lot) {
    if (!tradeTicket) {
      return null;
    }
    return tradeTicket.orderType === OrderEnums.Types.LIMIT ||
      tradeTicket.orderType === OrderEnums.Types.STOP ||
      tradeTicket.orderType === OrderEnums.Types.STOP_LIMIT
      ? this.calculateEstimatedProceeds(tradeTicket, lot) - lot.totalCost
      : lot.totalGainLoss;
  }

  refresh() {
    combineLatest([this.selectedAccount$, this.store.select(selectPositionForCurrentQuoteBySecurityType)])
      .pipe(
        take(1),
        filter(([account, position]) => !!account && !!position),
        tap(([account, position]) =>
          this.store.dispatch(
            createLoadLotsAction(
              account.accountId,
              position.positionId,
              this.tradeTicketService.tradeTicket.controls.orderId.value
            )
          )
        )
      )
      .subscribe();
  }

  handleOpenTaxLossHarvestingLink(): void {
    this.windowService.navigateToExternalLink(`${environment.investorDomain}${CONSTANTS.GAINS_LOSS}`, '_blank');
  }

  handleLearnMoreAboutCapitalGainsLinkClick(): void {
    this.windowService.navigateToExternalLink(`${environment.investorDomain}${CONSTANTS.CAPITAL_GAINS}`, '_blank');
  }

  previewButtonClick(amount: string): void {
    this.validateFormGroups(amount);

    if (!this.hasInvalidSelectedQtyShares) {
      this.mixedMethodWarningModal.modal.openModalDialog();
    }
  }

  private watchForCostBasisLotLoadError() {
    this.store
      .select(selectCostBasisState)
      .pipe(
        debounceTime(200),
        filter((costBasisState) => costBasisState.lotsLoadError !== null),
        tap(() => this.costBasisLotLoadErrorModal.open()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private buildLotTableSelectionData(
    costBasisLots: CostBasis.Lot[],
    tradeTicket: TradeTicketForm,
    holdingType: HoldingTypeEnum
  ) {
    return costBasisLots.map((lot) => {
      const lotTableFormGroup = this.formGroups.find((form) => form.formGroupID === lot.lotID);

      if (lotTableFormGroup) {
        return {
          ...lot,
          holdingType: holdingType,
          lotTableFormGroup,
          acquiredDate: lot.aquiredDate,
          proceeds: this.calculateEstimatedProceeds(tradeTicket, lot),
          gainLoss: this.calculateEstimatedGainLoss(tradeTicket, lot),
        };
      }
    });
  }

  /**
   * populate Form for Change Order
   */
  private populateFormForChangeOrder(): void {
    this.store
      .pipe(
        select(selectLotForm),
        filter((formValue) => Object.keys(formValue).length > 0),
        first(),
        tap((formValue) => {
          Object.keys(formValue).map((lotId) => {
            const formGroup = this.formGroups.find((form) => form.formGroupID === lotId);

            if (formGroup) {
              formGroup.controls.numberOfSharesSelected.setValue(formValue[lotId]);
              formGroup.controls.numberOfSharesSelected.markAsTouched();
              formGroup.controls.numberOfSharesSelected.markAsDirty();
            }
          });
        })
      )
      .subscribe();
  }

  /**
   * build Form on load cost basis lots
   */
  private watchForCostBasisLots() {
    this.store
      .pipe(
        select(selectCostBasisLots),
        withLatestFrom(this.tradeTicket$),
        filter(([_lots, tradeTicket]) => !!tradeTicket),
        first(),
        tap(([lots]) => {
          return this.buildForm(lots);
        })
      )
      .subscribe();
  }

  /**
   * if origin order has lots, and there is no lot form data in the store, then prepopulate the lot form data
   */
  private watchForOriginOrder() {
    this.origOrder$
      .pipe(
        withLatestFrom(this.store.select(selectLotForm), this.store.select(selectCostBasisLots)),
        filter(([order, lotForm]) => !!order?.lotIds && Object.keys(lotForm).length === 0),
        tap(([order, _lotForm, costBasisLots]) => {
          const lots = CostBasisUtil.makeLotFormDataFromOrder(order, costBasisLots);
          this.store.dispatch(createUpdateLotFormAction(lots));
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
