"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SecuritySecurityIdTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var SecuritySecurityIdTypeEnum;
(function (SecuritySecurityIdTypeEnum) {
  SecuritySecurityIdTypeEnum["ORDER_SECURITY_TYPE"] = "ORDER_SECURITY_TYPE";
  SecuritySecurityIdTypeEnum["CUSIP"] = "CUSIP";
  SecuritySecurityIdTypeEnum["SYMBOL"] = "SYMBOL";
  SecuritySecurityIdTypeEnum["SECURITY_TYPE"] = "SECURITY_TYPE";
  SecuritySecurityIdTypeEnum["DESCRIPTION"] = "DESCRIPTION";
  SecuritySecurityIdTypeEnum["SECURITY_KEY"] = "SECURITY_KEY";
})(SecuritySecurityIdTypeEnum = exports.SecuritySecurityIdTypeEnum || (exports.SecuritySecurityIdTypeEnum = {}));
