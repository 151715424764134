"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DelayedFundFundTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var DelayedFundFundTypeEnum;
(function (DelayedFundFundTypeEnum) {
  DelayedFundFundTypeEnum["MUTUAL_FUND_NEWS_MEDIA"] = "MUTUAL_FUND_NEWS_MEDIA";
  DelayedFundFundTypeEnum["MUTUAL_FUND_SUPPLEMENTAL"] = "MUTUAL_FUND_SUPPLEMENTAL";
  DelayedFundFundTypeEnum["MONEY_MARKET_FUND_NEWS_MEDIA"] = "MONEY_MARKET_FUND_NEWS_MEDIA";
  DelayedFundFundTypeEnum["MONEY_MARKET_FUND_SUPPLEMENTAL"] = "MONEY_MARKET FUND_SUPPLEMENTAL";
  DelayedFundFundTypeEnum["UNIT_INVESTMENT_TRUST"] = "UNIT_INVESTMENT_TRUST";
  DelayedFundFundTypeEnum["STRUCTURED_PRODUCT"] = "STRUCTURED_PRODUCT";
  DelayedFundFundTypeEnum["ANNUITY"] = "ANNUITY";
  DelayedFundFundTypeEnum["ALTERNATIVE_INVESTMENT_PRODUCT"] = "ALTERNATIVE_INVESTMENT_PRODUCT";
  DelayedFundFundTypeEnum["NEXTSHARES_EXCHANGE_TRADED_MANAGED_FUND"] = "NEXTSHARES_EXCHANGE_TRADED_MANAGED_FUND";
  DelayedFundFundTypeEnum["COLLECTIVE_INVESTMENT_TRUST"] = "COLLECTIVE_INVESTMENT_TRUST";
  DelayedFundFundTypeEnum["MANAGED_ACCOUNTS"] = "MANAGED_ACCOUNTS";
  DelayedFundFundTypeEnum["SEPARATE_ACCOUNTS"] = "SEPARATE_ACCOUNTS";
  DelayedFundFundTypeEnum["HEDGE_FUND"] = "HEDGE_FUND";
  DelayedFundFundTypeEnum["DEMAND_DEPOSIT_ACCOUNTS"] = "DEMAND_DEPOSIT_ACCOUNTS";
})(DelayedFundFundTypeEnum = exports.DelayedFundFundTypeEnum || (exports.DelayedFundFundTypeEnum = {}));
