"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ManageAutomaticInvestmentScheduleCommunicationChannelEnum = exports.ManageAutomaticInvestmentScheduleFrequencyCodeEnum = exports.ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum;
(function (ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum) {
  ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum["ACH"] = "ACH";
  ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum["POSITION"] = "POSITION";
})(ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum = exports.ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum || (exports.ManageAutomaticInvestmentScheduleDeliveryMethodCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ManageAutomaticInvestmentScheduleFrequencyCodeEnum;
(function (ManageAutomaticInvestmentScheduleFrequencyCodeEnum) {
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["WEEKLY"] = "WEEKLY";
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["BI_WEEKLY"] = "BI_WEEKLY";
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["SEMI_MONTHLY"] = "SEMI_MONTHLY";
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["MONTHLY"] = "MONTHLY";
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["QUARTERLY"] = "QUARTERLY";
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["SEMI_ANNUALLY"] = "SEMI_ANNUALLY";
  ManageAutomaticInvestmentScheduleFrequencyCodeEnum["ANNUALLY"] = "ANNUALLY";
})(ManageAutomaticInvestmentScheduleFrequencyCodeEnum = exports.ManageAutomaticInvestmentScheduleFrequencyCodeEnum || (exports.ManageAutomaticInvestmentScheduleFrequencyCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ManageAutomaticInvestmentScheduleCommunicationChannelEnum;
(function (ManageAutomaticInvestmentScheduleCommunicationChannelEnum) {
  ManageAutomaticInvestmentScheduleCommunicationChannelEnum["PHONE"] = "PHONE";
  ManageAutomaticInvestmentScheduleCommunicationChannelEnum["WEB"] = "WEB";
  ManageAutomaticInvestmentScheduleCommunicationChannelEnum["MAIL"] = "MAIL";
})(ManageAutomaticInvestmentScheduleCommunicationChannelEnum = exports.ManageAutomaticInvestmentScheduleCommunicationChannelEnum || (exports.ManageAutomaticInvestmentScheduleCommunicationChannelEnum = {}));
