"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExchangeTradingDetailsDurationEnum = exports.ExchangeTradingDetailsCategoryEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ExchangeTradingDetailsCategoryEnum;
(function (ExchangeTradingDetailsCategoryEnum) {
  ExchangeTradingDetailsCategoryEnum["MARKET"] = "MARKET";
  ExchangeTradingDetailsCategoryEnum["LIMIT"] = "LIMIT";
  ExchangeTradingDetailsCategoryEnum["STOP"] = "STOP";
  ExchangeTradingDetailsCategoryEnum["OR_BETTER"] = "OR_BETTER";
  ExchangeTradingDetailsCategoryEnum["STOP_LIMIT"] = "STOP_LIMIT";
  ExchangeTradingDetailsCategoryEnum["CLOSED"] = "CLOSED";
  ExchangeTradingDetailsCategoryEnum["WITH_OR_WITHOUT"] = "WITH_OR_WITHOUT";
  ExchangeTradingDetailsCategoryEnum["MARKET_ON_CLOSE"] = "MARKET_ON_CLOSE";
})(ExchangeTradingDetailsCategoryEnum = exports.ExchangeTradingDetailsCategoryEnum || (exports.ExchangeTradingDetailsCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ExchangeTradingDetailsDurationEnum;
(function (ExchangeTradingDetailsDurationEnum) {
  ExchangeTradingDetailsDurationEnum["DAY"] = "DAY";
  ExchangeTradingDetailsDurationEnum["IMMEDIATE_OR_CANCEL"] = "IMMEDIATE_OR_CANCEL";
  ExchangeTradingDetailsDurationEnum["GOOD_TILL_DAY"] = "GOOD_TILL_DAY";
  ExchangeTradingDetailsDurationEnum["SIXTY_DAY"] = "SIXTY_DAY";
  ExchangeTradingDetailsDurationEnum["EVENING"] = "EVENING";
  ExchangeTradingDetailsDurationEnum["FILL_OR_KILL"] = "FILL_OR_KILL";
})(ExchangeTradingDetailsDurationEnum = exports.ExchangeTradingDetailsDurationEnum || (exports.ExchangeTradingDetailsDurationEnum = {}));
