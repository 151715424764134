<ng-container
  *ngIf="{
    quote: quote$ | async,
    isSubPenny: isSubPenny$ | async,
    isVgEtf: quoteIsVgEtf$ | async,
    isDollarBasedGatekeeperOn: dollarBasedEnabled$ | async,
    actionIsBuyToCoverOrSellShort: actionIsBuyToCoverOrSellShort$ | async,
    order: order$ | async,
    orderCancel: orderCancel$ | async,
  } as view"
>
  <div class="twe-card">
    <div class="d-flex align-items-baseline">
      <h2 class="twe-card-header">{{ content.quote.realTimeQuote }}</h2>
      <c11n-icon-button
        class="c11n-space-stack-3x"
        [labelText]="content.quote.realTimeAlt"
        buttonType="button"
        size="small"
        variant="ghost"
        iconName="help"
        (clickEvent)="howToReadAQuote.open($event)"
      ></c11n-icon-button>
    </div>

    <c11n-banner *ngIf="view.quote?.isTradingHaltLULD" size="small" variant="warning" class="c11n-space-stack-3x">
      <div class="c11n-text-md--crop">
        {{ content.quote.warnings.limitUpLimitDown.message }}
        <button
          c11n-link
          type="button"
          [attr.aria-label]="content.quote.warnings.limitUpLimitDown.linkToModal"
          (click)="singleStockPriceBandsModal.open()"
        >
          {{ content.quote.warnings.limitUpLimitDown.linkToModal }}
        </button>
      </div>
    </c11n-banner>

    <c11n-banner *ngIf="view.isSubPenny" size="small" variant="warning" class="c11n-space-stack-3x">
      <div class="c11n-text-md--crop">
        {{ content.quote.warnings.subPennyStocks.message }}
        <a c11n-link href="tel:{{ content.quote.warnings.subPennyStocks.linkToContact }}">
          {{ content.quote.warnings.subPennyStocks.linkToContact }}.
        </a>
      </div>
    </c11n-banner>

    <div class="c11n-space-stack-3x">
      <div
        [attr.aria-live]="isChangeOrder ? 'off' : 'polite'"
        aria-atomic="true"
        [attr.aria-busy]="view?.quote ? 'false' : 'true'"
      >
        <div class="visually-hidden">{{ content.quote.realTimeQuote }}</div>

        <div class="c11n-text-md c11n-space-stack-0_5x" data-testid="txt-quote-name">
          {{ (view.quote | tweQuoteTickerLongName) || '&mdash;' }}
        </div>
        <div
          class="dollar-based-indicator"
          *ngIf="
            !isChangeOrder && view.isVgEtf && view.isDollarBasedGatekeeperOn && !view.actionIsBuyToCoverOrSellShort
          "
        >
          <c11n-chip
            *ngIf="!isAfterMarket"
            [labelText]="content.dollarBasedBannerIndicator.tradeInDollarsOrWholeShares"
          >
          </c11n-chip>
          <div class="dollar-based-indicator__link c11n-text-sm">
            <button
              c11n-link
              type="button"
              fontWeight="normal"
              (click)="
                dollarBasedInvestingLearnMoreModal.open();
                adobeService.sendAdobeTrackingOnClick('Real-Time Quote: Learn More', 'link')
              "
            >
              {{ content.dollarBasedBannerIndicator.learnMoreLink }}
            </button>
          </div>
        </div>

        <div class="d-flex align-items-baseline">
          <div
            class="c11n-text-xl twe-text-bold c11n-space-stack-1_5x c11n-space--force"
            data-testid="txt-quote-value"
            [innerHTML]="
              ((view.quote?.lastTradePrice == 0 && view.quote?.previousClosePrice > 0
                ? view.quote?.previousClosePrice
                : view.quote?.lastTradePrice
              ) | tweSubDollarCurrency) || '$&mdash;'
            "
          ></div>
          <div
            class="c11n-space-inline-left-2x"
            *ngIf="
              (isChangeOrder && view.order.orderDuration === orderDurationEnum.EVENING) ||
              (isCancelOrder && view.orderCancel.orderDuration === cancelOrderDurationEnum.EVENING) ||
              isAfterMarket
            "
          >
            <tcx-chip-with-icon
              [label]="content.quote.chip.afterMarketPrice"
              [chipSize]="'small'"
              [iconName]="'time'"
            ></tcx-chip-with-icon>
          </div>
        </div>
        <div
          class="twe-flex-center-items"
          [class.c11n-space-stack-0_5x]="view.quote"
          [class.c11n-space-stack-1x]="!view.quote"
          data-testid="txt-quote-last-trade"
        >
          <div *ngIf="!view.quote" class="text-truncate c11n-text-xs twe-c11n-color-ink-400 twe-flex-center-items">
            {{ content.quote.lastTrade }} &mdash;
          </div>

          <twe-refresh-button
            [hidden]="!view.quote"
            [isLoading]="marketDataService.isRefreshing"
            (refresh)="refresh()"
            [title]="content.loading.refresh"
            [ariaLabel]="'Real-Time Quote, Last Trade ' + (view.quote?.lastTradeDate | tweDate)"
          >
            <span class="d-inline-block" *ngIf="view.quote?.lastTradeDate; else noQuote">
              <tcx-timestamp
                [showTime]="true"
                [prefixText]="content.quote.lastTrade"
                [size]="timestampSizeEnum.XS"
                [date]="view.quote?.lastTradeDate | tweDate"
              ></tcx-timestamp>
            </span>
            <ng-template #noQuote>
              <span class="c11n-text-xs">{{ content.quote.lastTrade }} &mdash;</span>
            </ng-template>
          </twe-refresh-button>
        </div>

        <div class="visually-hidden">{{ content.quote.detailsDisplayed }}</div>
      </div>
      <div class="text-truncate c11n-text-xs twe-c11n-color-ink-400 text-uppercase">
        <div class="twe-flex-center-items" data-testid="txt-quote-last-size">
          {{ content.quote.lastSize }}:
          {{ (view.quote?.lastTradeVolume | number) || '&mdash;' }}

          <span class="twe-flex-center-items c11n-space-inline-left-3x c11n-space--force">
            {{ content.quote.exchange }}:

            <button
              c11n-link
              type="button"
              class="c11n-space-inline-left-0_5x c11n-space--force normal-font-weight margin-top-3"
              variant="primary-independent"
              *ngIf="view.quote?.lastTradeExChg"
              (click)="openExchangeNameModal(view.quote?.lastTradeExChg, view.quote?.lastTradeExChgName)"
              attr.aria-label="Click or tap for {{ view.quote?.lastTradeExChg }} exchange abbreviation details."
            >
              {{ view.quote?.lastTradeExChg }}
            </button>

            <ng-container *ngIf="!view.quote?.lastTradeExChg">&mdash;</ng-container>
          </span>
        </div>
      </div>
    </div>

    <div>
      <div class="quote-row quote-row--border-bottom-gray quote-row--border-top-gray">
        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" data-testid="txt-quote-heading">{{ content.quote.change }}</span>
            <span class="quote-data quote-data--inline-icon" data-testid="txt-quote-data">
              <c11n-icon
                *ngIf="view.quote?.todaysPriceChange > 0"
                [name]="'positive-change'"
                [size]="'small'"
                [scale]="normal"
              ></c11n-icon>
              <c11n-icon
                *ngIf="view.quote?.todaysPriceChange < 0"
                [name]="'negative-change'"
                [size]="'small'"
                [scale]="normal"
              ></c11n-icon>

              {{ (view.quote?.todaysPriceChange | tweSubDollarCurrency) || '$&mdash;' }}
              ({{ (view.quote?.todaysPriceChangePercent | number : '1.2-2') || '&mdash;' }})%
            </span>
          </span>
        </section>

        <section class="quote-row__item">
          <span role="text" *ngIf="!marketDataService?.panelIsOpen; else bidSize">
            <ng-container *ngTemplateOutlet="bidSize"></ng-container>
          </span>
        </section>
      </div>

      <div
        class="quote-row quote-row--border-bottom-black"
        [ngClass]="{ 'quote-row--border-bottom-gray': marketDataService?.panelIsOpen }"
      >
        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" data-testid="txt-quote-heading">{{ content.quote.volume }}</span>
            <span class="quote-data" data-testid="txt-quote-data">{{
              (view.quote?.todaysVolume | number : '1.0-0') || '&mdash;'
            }}</span>
          </span>
        </section>

        <section class="quote-row__item">
          <span role="text" *ngIf="!marketDataService?.panelIsOpen; else askSize">
            <ng-container *ngTemplateOutlet="askSize"></ng-container>
          </span>
        </section>
      </div>
    </div>

    <c11n-details
      class="quote-details"
      [labelWeight]="'normal'"
      [expandLabelText]="content.quote.showDetailedQuote"
      [collapseLabelText]="content.quote.hideDetailedQuote"
      [isExpanded]="initialState"
      [labelAlign]="'center'"
      (detailsToggle)="handlePanelToggle($event)"
    >
      <div class="quote-row quote-row--border-bottom-gray">
        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" data-testid="txt-quote-heading">{{ content.quote.open }}</span>
            <span class="quote-data" data-testid="txt-quote-data">{{
              (view.quote?.openPrice | tweSubDollarCurrency) || '$&mdash;'
            }}</span>
          </span>
        </section>

        <section class="quote-row__item">
          <span role="text">
            <span
              class="quote-heading quote-heading--uppercase"
              [attr.aria-label]="content.quote.day"
              data-testid="txt-quote-heading"
              >{{ content.quote.day }}</span
            >
            <span class="quote-data" data-testid="txt-quote-data"
              >{{ (view.quote?.dayLowPrice | tweSubDollarCurrency) || '$&mdash;' }} -
              {{ (view.quote?.dayHighPrice | tweSubDollarCurrency) || '$&mdash;' }}</span
            >
          </span>
        </section>
      </div>

      <div class="quote-row quote-row--border-bottom-gray">
        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" [attr.aria-label]="content.quote.prevClose" data-testid="txt-quote-heading">{{
              content.quote.prevCloseAbbr
            }}</span>
            <span class="quote-data" data-testid="txt-quote-data">{{
              (view.quote?.previousClosePrice | tweSubDollarCurrency) || '$&mdash;'
            }}</span>
          </span>
        </section>

        <section class="quote-row__item">
          <span role="text">
            <span
              class="quote-heading"
              [attr.aria-label]="content.quote.fiftyTwoWeek"
              data-testid="txt-quote-heading"
              >{{ content.quote.fiftyTwoWeekAbbr }}</span
            >
            <span class="quote-data" data-testid="txt-quote-data"
              >{{ (view.quote?.fiftyTwoWeekLowPrice | tweSubDollarCurrency) || '$&mdash;' }} -
              {{ (view.quote?.fiftyTwoWeekHighPrice | tweSubDollarCurrency) || '$&mdash;' }}</span
            >
          </span>
        </section>
      </div>

      <div class="quote-row quote-row--border-bottom-gray">
        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" [attr.aria-label]="content.quote.divDate" data-testid="txt-quote-heading">{{
              content.quote.divDateAbbr
            }}</span>
            <span class="quote-data" data-testid="txt-quote-data">{{
              (view.quote?.dividendPayDate | date : 'M/d/yyyy') || '$&mdash;'
            }}</span>
          </span>
        </section>

        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" [attr.aria-label]="content.quote.exDivDate" data-testid="txt-quote-heading">{{
              content.quote.exDivDateAbbr
            }}</span>
            <span class="quote-data" data-testid="txt-quote-data">{{
              (view.quote?.exDividendDate | date : 'M/d/yyyy') || '$&mdash;'
            }}</span>
          </span>
        </section>
      </div>

      <div class="quote-row quote-row-expanded quote-row--border-bottom-black">
        <section class="quote-row__item">
          <span role="text">
            <span class="quote-heading" data-testid="txt-quote-heading">{{ content.quote.primaryExchange }}</span>
            <span class="quote-data" data-testid="txt-quote-data">{{ view.quote?.primaryExChgName || '&mdash;' }}</span>
          </span>
        </section>
      </div>
    </c11n-details>

    <twe-shares-held></twe-shares-held>
  </div>

  <ng-template #bidSize>
    <span class="quote-heading quote-heading--inline-icon" data-testid="txt-quote-heading">
      <span class="visually-hidden">{{ content.quote.bidTimesSize }}</span>
      <span aria-hidden="true">{{ content.quote.bidTimesSizeAbbr }}</span>
      <button
        c11n-link
        type="button"
        class="c11n-space-inline-left-0_5x normal-font-weight"
        variant="primary-independent"
        *ngIf="view.quote?.bidPriceExChg && marketDataService?.panelIsOpen"
        (click)="openExchangeNameModal(view.quote?.bidPriceExChg, view.quote?.bidPriceExChgName)"
      >
        {{ view.quote?.bidPriceExChg }}
      </button>
    </span>

    <span class="visually-hidden" id="bidTimesSizeValue{{ uniqueID }}"
      >{{ (view.quote?.bidPrice | tweSubDollarCurrency) || '$&mdash;' }} &times;
      {{ (view.quote?.bidSize | number : '1.0-0') || '&mdash;' }}
    </span>
    <span class="quote-data quote-data--inline-icon" aria-hidden="true" data-testid="txt-quote-data">
      <c11n-icon
        *ngIf="view.quote?.isBidOutOfRange === 'true'"
        name="warning"
        size="small"
        class="icon-container"
      ></c11n-icon>
      {{ (view.quote?.bidPrice | tweSubDollarCurrency) || '$&mdash;' }}&times;{{
        (view.quote?.bidSize | number : '1.0-0') || '&mdash;'
      }}
    </span>
  </ng-template>

  <ng-template #askSize>
    <span class="quote-heading quote-heading--inline-icon" data-testid="txt-quote-heading">
      <span class="visually-hidden">{{ content.quote.askTimesSize }}</span>
      <span aria-hidden="true">{{ content.quote.askTimesSizeAbbr }}</span>
      <button
        c11n-link
        type="button"
        class="c11n-space-inline-left-0_5x normal-font-weight"
        variant="primary-independent"
        *ngIf="view.quote?.askPriceExChg && marketDataService?.panelIsOpen"
        (click)="openExchangeNameModal(view.quote?.askPriceExChg, view.quote?.askPriceExChgName)"
      >
        {{ view.quote?.askPriceExChg }}
      </button>
    </span>

    <span class="visually-hidden" id="askTimesSizeValue{{ uniqueID }}"
      >{{ (view.quote?.askPrice | tweSubDollarCurrency) || '$&mdash;' }} &times;
      {{ (view.quote?.askSize | number : '1.0-0') || '&mdash;' }}</span
    >
    <span class="quote-data quote-data--inline-icon" aria-hidden="true" data-testid="txt-quote-data">
      <c11n-icon
        *ngIf="view.quote?.isAskOutOfRange === 'true'"
        name="warning"
        size="small"
        class="icon-container"
      ></c11n-icon>
      {{ (view.quote?.askPrice | tweSubDollarCurrency) || '$&mdash;' }}&times;{{
        (view.quote?.askSize | number : '1.0-0') || '&mdash;'
      }}
    </span>
  </ng-template>

  <!-- Modals -->
  <twe-how-to-read-a-quote-modal #howToReadAQuote></twe-how-to-read-a-quote-modal>
  <twe-single-stock-price-bands-modal #singleStockPriceBandsModal></twe-single-stock-price-bands-modal>
  <twe-dollar-based-investing-learn-more-modal
    #dollarBasedInvestingLearnMoreModal
  ></twe-dollar-based-investing-learn-more-modal>
  <c11n-modal-dialog #exchangeNameModal [headingText]="exchangeId">
    <p c11n-modal-dialog-body>
      {{ exchangeName }}
    </p>
  </c11n-modal-dialog>
</ng-container>
