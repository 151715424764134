"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeOrderTransactionDestinationDestinationTypeEnum = exports.ChangeOrderTransactionDestinationCostBasisMethodEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ChangeOrderTransactionDestinationCostBasisMethodEnum;
(function (ChangeOrderTransactionDestinationCostBasisMethodEnum) {
  ChangeOrderTransactionDestinationCostBasisMethodEnum["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  ChangeOrderTransactionDestinationCostBasisMethodEnum["SPECIFIC_IDENTIFICATION"] = "SPECIFIC_IDENTIFICATION";
  ChangeOrderTransactionDestinationCostBasisMethodEnum["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
  ChangeOrderTransactionDestinationCostBasisMethodEnum["MINIMUM_TAX"] = "MINIMUM_TAX";
  ChangeOrderTransactionDestinationCostBasisMethodEnum["AVERAGE_COST"] = "AVERAGE_COST";
  ChangeOrderTransactionDestinationCostBasisMethodEnum["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
})(ChangeOrderTransactionDestinationCostBasisMethodEnum = exports.ChangeOrderTransactionDestinationCostBasisMethodEnum || (exports.ChangeOrderTransactionDestinationCostBasisMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ChangeOrderTransactionDestinationDestinationTypeEnum;
(function (ChangeOrderTransactionDestinationDestinationTypeEnum) {
  ChangeOrderTransactionDestinationDestinationTypeEnum["BROKERAGE_HOLDING"] = "BROKERAGE_HOLDING";
  ChangeOrderTransactionDestinationDestinationTypeEnum["SWEEP_FUND"] = "SWEEP_FUND";
  ChangeOrderTransactionDestinationDestinationTypeEnum["FIVE_TWENTY_NINE_ACCOUNT"] = "FIVE_TWENTY_NINE_ACCOUNT";
  ChangeOrderTransactionDestinationDestinationTypeEnum["FEES"] = "FEES";
})(ChangeOrderTransactionDestinationDestinationTypeEnum = exports.ChangeOrderTransactionDestinationDestinationTypeEnum || (exports.ChangeOrderTransactionDestinationDestinationTypeEnum = {}));
