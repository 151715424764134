"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DetailedQuotePriceChangeDirectionEnum = exports.DetailedQuoteVendorEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var DetailedQuoteVendorEnum;
(function (DetailedQuoteVendorEnum) {
  DetailedQuoteVendorEnum["FACTSET"] = "FACTSET";
  DetailedQuoteVendorEnum["REFINITIV"] = "REFINITIV";
})(DetailedQuoteVendorEnum = exports.DetailedQuoteVendorEnum || (exports.DetailedQuoteVendorEnum = {}));
/**
    * @export
    * @enum {string}
    */
var DetailedQuotePriceChangeDirectionEnum;
(function (DetailedQuotePriceChangeDirectionEnum) {
  DetailedQuotePriceChangeDirectionEnum["POSITIVE"] = "POSITIVE";
  DetailedQuotePriceChangeDirectionEnum["NEGATIVE"] = "NEGATIVE";
  DetailedQuotePriceChangeDirectionEnum["NEUTRAL"] = "NEUTRAL";
})(DetailedQuotePriceChangeDirectionEnum = exports.DetailedQuotePriceChangeDirectionEnum || (exports.DetailedQuotePriceChangeDirectionEnum = {}));
