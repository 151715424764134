import { Account } from '@app/etfs-equities/models';
import { SingleErrorFormControl } from '@vanguard/trade-standard-forms-lib-ng-17';
import { Observable, Subject } from 'rxjs';

// models: https://bitbucket.opst.c1.vanguard.com/projects/TRADECX/repos/trade-micro-front-ends.frontend/browse/projects/account-selector/models/account-selector.component.model.ts

export interface AccountSelectorConfig {
  hideDetails?: boolean;
  autoSelectFirstAccount?: boolean;
  autoSelectOnlyAccount?: boolean;
  suppressProvisionalVbaAccountAlerts?: boolean;
  description?: string;
  displayMarginBuyingPower?: boolean;
  label?: string;
  id?: string;
  showBalanceInAccountSelector?: boolean;
  resetFormControlOnAccountsUpdated?: boolean;
  rememberSelectedAccount?: boolean;
  readOnly?: boolean;
}

export interface AccountSelectorAccount extends Omit<Account.Account, 'accountId'> {
  accountId: string;
}

export interface AccountSelectorConfigProvider {
  getConfig: () => AccountSelectorConfig | Observable<AccountSelectorConfig>;
}

export interface BrokerageAccountProvider {
  getBrokerageAccounts: () => Observable<Array<AccountSelectorAccount>>;
}

export interface FormControlProvider {
  getFormControl: () => SingleErrorFormControl<string>;
}

export interface AccountSelectorControlPlaneProvider {
  getControlPlane: () => Subject<ControlPlaneEvent>;
}

export interface AccountSelectorComponentTrackingEventHandler {
  onTrackingEvent: (trackingEvent: AccountSelectorTrackingEvent) => void;
}

export interface ControlPlaneEvent {
  eventType: ControlPlaneEventType;
  eventData?: any;
}

export enum ControlPlaneEventType {
  NOOP = 'NOOP',
  REFRESH_FUNDS_AVAILABLE = 'REFRESH_FUNDS_AVAILABLE',
  REFRESH_BROKERAGE_ACCOUNTS = 'REFRESH_BROKERAGE_ACCOUNTS',
  FORCE_CHANGE_DETECTION = 'FORCE_CHANGE_DETECTION',
}

export enum AccountSelectorTrackingEvent {
  ACCOUNT_SELECTOR_LOADED = 'ACCOUNT_SELECTOR_LOADED',
  CLICK_VIEW_ACCOUNT_DETAILS = 'CLICK_VIEW_ACCOUNT_DETAILS',
  CLICK_FUNDS_AVAILABLE_TO_TRADE_TOOLTIP = 'CLICK_FUNDS_AVAILABLE_TO_TRADE_TOOLTIP',
  CLICK_MARGIN_BUYING_POWER_TOOLTIP = 'CLICK_MARGIN_BUYING_POWER_TOOLTIP',
  PVBA_RESTRICTIONS_MODAL_LOADED = 'PVBA_RESTRICTIONS_MODAL_LOADED',
  CLICK_PVBA_RESTRICTIONS_BUTTON = 'CLICK_PVBA_RESTRICTIONS_BUTTON',
}
