<div class="open-order-item__cell">
  <div class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs" data-testid="open-order-card-label">
    {{ label }}
  </div>

  <div class="text-truncate twe-text-bold c11n-text-sm" data-testid="open-order-card-data">
    {{ value }}
  </div>

  <div
    *ngIf="subValue"
    class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
    data-testid="open-order-card-data"
  >
    {{ subValue }}
  </div>
  <div
    *ngIf="secondSubValue"
    class="text-truncate text-uppercase twe-c11n-color-ink-400 c11n-text-xs"
    data-testid="open-order-card-data"
  >
    {{ secondSubValue }}
  </div>
</div>
