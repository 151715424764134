import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { CashMarginIndicatorTypes, OrderEnums, SecurityClassTypes } from '@app/etfs-equities/enums';
import { Account, JsonContent, OpenOrder, OpenOrderExtended } from '@app/etfs-equities/models';
import { SubDollarCurrency } from '@app/etfs-equities/pipes';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';

import {
  TweOpenOrdersOrderCategoryTypesEnum,
  TweOpenOrdersOrderStatusTypesEnum,
} from '../../enums/open-orders/open-orders.enum';

@Component({
  selector: 'twe-open-orders-component',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpenOrdersComponent {
  //  Decorators...
  @Input() selectedAccount: Account.Account;
  @Input() set openOrders(value: OpenOrder[]) {
    this.openOrdersExtended = value?.map((openOrder) => {
      const orderStatusDisplayText = OrderUtil.orderStatusDisplayText(openOrder);

      return {
        ...openOrder,
        orderStatusDisplayText,
        orderColor: OrderUtil.orderColor(openOrder, orderStatusDisplayText),
      };
    });
  }
  @Input() isLoading: boolean;
  @Input() orderRetrievalFailed: boolean;

  //  Public variables...
  readonly TRADE_PATH = CONSTANTS.TRADE_PATH;
  openOrdersExtended: OpenOrderExtended[];
  content: JsonContent = content;

  // Public Enums...
  amountIndicators = OrderEnums.AmountIndicators;
  securityClassTypes = SecurityClassTypes;
  statusDetailEnum = TweOpenOrdersOrderStatusTypesEnum;
  orderCategoryEnum = TweOpenOrdersOrderCategoryTypesEnum;

  // Public Labels...
  orderCategoryLabel = OrderUtil.orderCategoryLabel;
  orderInstructionCodeLabel = OrderUtil.orderInstructionCodeLabel;
  securityClasses = OrderUtil.securityIsEquity;

  get isMarginAccount(): boolean {
    return this.selectedAccount.marginCode === CashMarginIndicatorTypes.MARGIN;
  }

  constructor(private readonly tweCurrencyPipe: SubDollarCurrency) {}

  getNumberOfSharesOrDollars(order: OpenOrder): string {
    let amount: string;
    if (
      this.securityClasses.includes(order.securityType) ||
      (order.orderSecurityType.includes('MUTUAL_FUND') && order.amountIndicator !== 'DOLS')
    ) {
      if (
        order.statusDetail === this.statusDetailEnum.EXECUTED ||
        order.statusDetail === this.statusDetailEnum.PARTIAL_EXECUTION
      ) {
        amount = Number(order.executedShareQuantity).toFixed(4);
      } else {
        if (order.amountIndicator === this.amountIndicators.DOLLARS) {
          amount = ' - ';
        } else {
          amount = Number(order.originalShareQuantity).toFixed(4);
        }
      }
    } else {
      amount = this.tweCurrencyPipe.transform(order.orderValue);
    }
    return amount;
  }
}
