import {
  initialScreenSizeState,
  ScreenSizeActionsUnion,
  ScreenSizeActionTypes,
  ScreenSizeState,
} from '@etfs-equities/store';

export function screenSizeReducer(
  state: ScreenSizeState = initialScreenSizeState,
  action: ScreenSizeActionsUnion
): ScreenSizeState {
  if (action.type === ScreenSizeActionTypes.SET_SCREEN_SIZE) {
    return { screenSize: action.payload };
  } else {
    return state;
  }
}
