import { CurrencyPipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CashMarginIndicatorTypes, OrderEnums } from '@app/etfs-equities/enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { OrderCancel } from '@app/etfs-equities/models/order-cancel';
import { CostBasisExpandPipe, SubDollarCurrency } from '@app/etfs-equities/pipes';
import { selectSelectedAccount, TayneState } from '@app/etfs-equities/store';
import {
  selectOrderCancel,
  selectOrderCancelFormatSharesDisplay,
} from '@app/etfs-equities/store/selectors/order-cancel/order-cancel.selector';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { OrderSummarySection, OrderSummarySectionRow } from '@vanguard/trade-ui-components-lib-ng-17';
import { combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'twe-cancel-order-detail',
  templateUrl: './cancel-order-detail.component.html',
  styleUrls: ['./cancel-order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancelOrderDetailComponent implements OnInit {
  //  Public observables/subjects...
  orderCancel$: Observable<OrderCancel>;
  selectedAccount$: Observable<Account.Account>;
  formatSharesDisplay$: Observable<string>;
  orderSummarySection$: Observable<OrderSummarySection>;

  //  Public variables...

  content: JsonContent = content;

  constructor(
    private readonly store: Store<TayneState>,
    private readonly titleCasePipe: TitleCasePipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly tweSubDollarCurrency: SubDollarCurrency,
    private readonly currencyPipe: CurrencyPipe,
    private readonly tweCostBasisExpand: CostBasisExpandPipe
  ) {}

  ngOnInit(): void {
    this.orderCancel$ = this.store.pipe(select(selectOrderCancel));
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.formatSharesDisplay$ = this.store.pipe(select(selectOrderCancelFormatSharesDisplay));

    this.orderSummarySection$ = combineLatest([
      this.orderCancel$,
      this.selectedAccount$,
      this.formatSharesDisplay$,
    ]).pipe(
      map(([order, account, formatSharesDisplay]) => {
        return {
          titleLeft: content.orderDetail.account,
          subTitleLeft: this.getAccountName(account),
          isTitleUnderlined: true,
          rows: this.getRows(order, account, formatSharesDisplay),
        };
      })
    );
  }

  getDynamicContent(orderDetail: OrderCancel): string | null {
    switch (orderDetail?.orderType) {
      case OrderEnums.Types.MARKET:
        return orderDetail.amountType === OrderEnums.AmountTypes.DOLLARS
          ? this.content.dynamicContentDollarBasedOrders
          : this.content.dynamicContentMarketOrder;
      case OrderEnums.Types.STOP:
        return this.content.dynamicContentStopOrders;
      case OrderEnums.Types.LIMIT:
      case OrderEnums.Types.STOP_LIMIT:
        return this.content.dynamicContentLimitAndStopOrders;
      default:
        return null;
    }
  }

  private getAccountName(account: Account.Account): string {
    const name = account?.accountName;
    const marginIndicator =
      account?.marginCode === CashMarginIndicatorTypes.MARGIN ? ` (${content.labels.margin})` : '';

    return name ? name + marginIndicator : '—';
  }

  private getRows(order: OrderCancel, account: Account.Account, formatSharesDisplay: string): OrderSummarySectionRow[] {
    if (!order) return [];

    const titleFontWeight = 400;
    const rowConfig = {
      isUnderlined: true,
      flexibleRightColumn: true,
    };
    const rows = [
      {
        ...rowConfig,
        titleLeft: {
          text: content.labels.order,
          titleFontWeight,
        },
        titleRight: `#${order.orderId || '—'}`,
      },
      {
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.transaction,
          titleFontWeight,
        },
        titleRight: order.transactionType || '—',
      },
      {
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.security,
          titleFontWeight,
        },
        titleRight: `${order.description || '—'} (${order.ticker || '—'})`,
      },
      {
        ...rowConfig,
        titleLeft: {
          text:
            order.amountType === OrderEnums.AmountTypes.DOLLARS
              ? content.labels.estimatedShares
              : content.labels.shares,
          titleFontWeight,
        },
        titleRight: this.getEstimatedShares(order, formatSharesDisplay),
      },
      {
        ...rowConfig,
        titleLeft: {
          text:
            order.amountType === OrderEnums.AmountTypes.DOLLARS
              ? content.labels.amount
              : content.labels.estimatedAmount,
          titleFontWeight,
        },
        titleRight:
          (order.amountType === OrderEnums.AmountTypes.DOLLARS
            ? this.tweSubDollarCurrency.transform(order.shares)
            : this.tweSubDollarCurrency.transform(order.estimatedDollarValue)) || '$—',
      },
      {
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.orderType,
          titleFontWeight,
        },
        titleRight: this.titleCasePipe.transform(order.orderType) || '—',
      },
      {
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.duration,
          titleFontWeight,
        },
        titleRight: order.orderDuration || '—',
      },
    ];

    if (account?.marginCode === CashMarginIndicatorTypes.MARGIN) {
      rows.splice(2, 0, {
        ...rowConfig,
        titleLeft: {
          text: content.labels.marginTradeType,
          titleFontWeight,
        },
        titleRight: this.titleCasePipe.transform(order.securityAccountType) || '—',
      });
    }

    if (order.stopPrice) {
      rows.splice(7, 0, {
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.stopPrice,
          titleFontWeight,
        },
        titleRight: this.currencyPipe.transform(order.stopPrice),
      });
    }

    if (order.limitPrice) {
      rows.splice(7, 0, {
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.limitPrice,
          titleFontWeight,
        },
        titleRight: this.currencyPipe.transform(order.limitPrice),
      });
    }

    if (order.costBasisMethod) {
      rows.push({
        ...rowConfig,
        titleLeft: {
          text: content.orderDetail.costBasis,
          titleFontWeight,
        },
        titleRight: this.tweCostBasisExpand.transform(order.costBasisMethod),
      });
    }

    return rows;
  }

  private getEstimatedShares(order: OrderCancel, formatSharesDisplay: string): string {
    switch (order.amountType) {
      case OrderEnums.AmountTypes.DOLLARS:
        return this.decimalPipe.transform(order.estimatedShareQuantity, '1.4-4') || '—';
      case OrderEnums.AmountTypes.SHARES:
        if (order.executedShareQuantity) {
          return `${content.orderStatusLabel.executed}: ${order.executedShareQuantity} | ${content.orderStatusLabel.open}: ${order.remainingQuantity}`;
        }
        return formatSharesDisplay || '—';
      default:
        return '—';
    }
  }
}
