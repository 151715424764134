"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutomaticInvestmentScheduleFrequencyCodeEnum = exports.AutomaticInvestmentScheduleDeliveryMethodCodeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AutomaticInvestmentScheduleDeliveryMethodCodeEnum;
(function (AutomaticInvestmentScheduleDeliveryMethodCodeEnum) {
  AutomaticInvestmentScheduleDeliveryMethodCodeEnum["ACH"] = "ACH";
  AutomaticInvestmentScheduleDeliveryMethodCodeEnum["POSITION"] = "POSITION";
})(AutomaticInvestmentScheduleDeliveryMethodCodeEnum = exports.AutomaticInvestmentScheduleDeliveryMethodCodeEnum || (exports.AutomaticInvestmentScheduleDeliveryMethodCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AutomaticInvestmentScheduleFrequencyCodeEnum;
(function (AutomaticInvestmentScheduleFrequencyCodeEnum) {
  AutomaticInvestmentScheduleFrequencyCodeEnum["WEEKLY"] = "WEEKLY";
  AutomaticInvestmentScheduleFrequencyCodeEnum["BI_WEEKLY"] = "BI_WEEKLY";
  AutomaticInvestmentScheduleFrequencyCodeEnum["SEMI_MONTHLY"] = "SEMI_MONTHLY";
  AutomaticInvestmentScheduleFrequencyCodeEnum["MONTHLY"] = "MONTHLY";
  AutomaticInvestmentScheduleFrequencyCodeEnum["QUARTERLY"] = "QUARTERLY";
  AutomaticInvestmentScheduleFrequencyCodeEnum["SEMI_ANNUALLY"] = "SEMI_ANNUALLY";
  AutomaticInvestmentScheduleFrequencyCodeEnum["ANNUALLY"] = "ANNUALLY";
})(AutomaticInvestmentScheduleFrequencyCodeEnum = exports.AutomaticInvestmentScheduleFrequencyCodeEnum || (exports.AutomaticInvestmentScheduleFrequencyCodeEnum = {}));
