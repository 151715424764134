<ng-container
  *ngIf="{
    order: order$ | async,
  } as view"
>
  <div *ngIf="!isBeacon" class="c11n-space-stack-3x c11n-space--force">
    <tcx-order-confirmation-header
      data-testid="txt-order-confirmation"
      [refreshButtonText]="content.loading.refresh"
      [headerIconName]="'green-check-mark'"
      orderDetails="{{ content?.labels.orderNumber }}{{ confirmationNumber || view.order?.orderId }}"
      [submitDetails]="{
        date: submittedOn || view.order?.enteredTimestamp | tweDate,
        prefix: content?.labels.submittedAt
      }"
      [orderStatus]="{
        date: submittedOn || view.order?.enteredTimestamp | tweDate,
        datePrefix: content?.labels.asOf.toLowerCase(),
        status: view.order?.orderStatusTweText,
        statusPrefix: content?.labels.orderStatus + ':'
      }"
      [leftButtonText]="content?.labels.viewOpenOrders"
      leftButtonIcon="document"
      [rightButtonText]="content?.labels.print"
      rightButtonIcon="print"
      (refreshButtonClick)="loadOrderStatus()"
      (leftButtonClick)="navigateToOpenOrders()"
      (rightButtonClick)="printComponent()"
    ></tcx-order-confirmation-header>
  </div>

  <ng-container *ngIf="isBeacon">
    <div class="d-flex received-order-header">
      <c11n-icon name="success-fill" size="medium" altText="Success"></c11n-icon>

      <h2 class="order-confirm twe-text-bold" data-testid="txt-order-confirmation">
        {{ content.labels.receivedOrder }}{{ isChangeOrder ? view.order?.confirmationNumber : view.order?.orderId }}
      </h2>
    </div>

    <div class="page-heading c11n-space-stack-3x c11n-space--force">
      <p class="m-0">
        <tcx-timestamp
          [showTime]="true"
          [prefixText]="content.labels.submittedAt"
          [size]="timestampSizeEnum.XS"
          [date]="view.order?.enteredTimestamp | tweDate"
        ></tcx-timestamp>

        <br class="d-block d-md-none" />

        <span class="visually-hidden" id="sr-open-new-window">{{ content.openNewWindow }}</span>
        <twe-prospectus-link [order]="view.order"></twe-prospectus-link>
      </p>
    </div>
  </ng-container>
</ng-container>
