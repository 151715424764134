export enum ScreenSize {
  XXL = 'XXL',
  XL = 'XL',
  LG = 'LG',
  MD = 'MD',
  SM = 'SM',
  XS = 'XS',
}

export interface ScreenSizeState {
  screenSize: ScreenSize | null;
}

export const initialScreenSizeState: ScreenSizeState = {
  screenSize: null,
};
