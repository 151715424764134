import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from '@app/etfs-equities/constants';
import { JsonContent, Order } from '@app/etfs-equities/models';
import { WindowService } from '@app/etfs-equities/services';
import { createLoadOrderStatusAction, selectOrder, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { TimestampSize } from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-order-confirmation-header',
  templateUrl: './order-confirmation-header.component.html',
  styleUrl: './order-confirmation-header.component.scss',
})
export class OrderConfirmationHeaderComponent implements OnInit {
  @Input({ required: true }) accountId: string;
  @Input({ required: true }) orderId: string;
  @Input() confirmationNumber: string;
  @Input() submittedOn: string;
  @Input() isChangeOrder = false;

  // Public variables...
  isBeacon = false;
  content: JsonContent = content;
  protected readonly timestampSizeEnum = TimestampSize;

  // Public observables/subjects...
  order$: Observable<Order.Order>;

  constructor(private readonly windowService: WindowService, private readonly store: Store<TayneState>) {}

  ngOnInit() {
    this.isBeacon = this.windowService.getIsBeacon();
    this.order$ = this.store.select(selectOrder);

    this.loadOrderStatus();
  }

  loadOrderStatus(): void {
    if (this.accountId && this.orderId) {
      this.store.dispatch(createLoadOrderStatusAction(this.accountId, this.orderId));
    }
  }

  navigateToOpenOrders(): void {
    this.windowService.router.navigate([CONSTANTS.OPEN_ORDERS_PATH]);
  }

  printComponent(): void {
    this.windowService.print();
  }
}
