"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AccountPositionDetailsOptionTradingObjectiveEnum = exports.AccountPositionDetailsOptionTotalRangeEnum = exports.AccountPositionDetailsOptionLiquidityRangeEnum = exports.AccountPositionDetailsOptionIncomeRangeEnum = exports.AccountPositionDetailsAccountStatusEnum = exports.AccountPositionDetailsOptionPermissionEnum = exports.AccountPositionDetailsNasdAffiliationEnum = exports.AccountPositionDetailsMarginPermissionEnum = exports.AccountPositionDetailsReinvestPreferenceCodeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsReinvestPreferenceCodeEnum;
(function (AccountPositionDetailsReinvestPreferenceCodeEnum) {
  AccountPositionDetailsReinvestPreferenceCodeEnum["CASH"] = "CASH";
  AccountPositionDetailsReinvestPreferenceCodeEnum["REINVEST"] = "REINVEST";
  AccountPositionDetailsReinvestPreferenceCodeEnum["UNDEFINED"] = "UNDEFINED";
})(AccountPositionDetailsReinvestPreferenceCodeEnum = exports.AccountPositionDetailsReinvestPreferenceCodeEnum || (exports.AccountPositionDetailsReinvestPreferenceCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsMarginPermissionEnum;
(function (AccountPositionDetailsMarginPermissionEnum) {
  AccountPositionDetailsMarginPermissionEnum["CASH"] = "CASH";
  AccountPositionDetailsMarginPermissionEnum["MARGIN"] = "MARGIN";
})(AccountPositionDetailsMarginPermissionEnum = exports.AccountPositionDetailsMarginPermissionEnum || (exports.AccountPositionDetailsMarginPermissionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsNasdAffiliationEnum;
(function (AccountPositionDetailsNasdAffiliationEnum) {
  AccountPositionDetailsNasdAffiliationEnum["AFFILIATED"] = "AFFILIATED";
  AccountPositionDetailsNasdAffiliationEnum["NOT_AFFILIATED"] = "NOT_AFFILIATED";
})(AccountPositionDetailsNasdAffiliationEnum = exports.AccountPositionDetailsNasdAffiliationEnum || (exports.AccountPositionDetailsNasdAffiliationEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsOptionPermissionEnum;
(function (AccountPositionDetailsOptionPermissionEnum) {
  AccountPositionDetailsOptionPermissionEnum["NO_PAPERS"] = "NO_PAPERS";
  AccountPositionDetailsOptionPermissionEnum["LEVEL_1"] = "LEVEL_1";
  AccountPositionDetailsOptionPermissionEnum["LEVEL_2"] = "LEVEL_2";
  AccountPositionDetailsOptionPermissionEnum["LEVEL_3"] = "LEVEL_3";
  AccountPositionDetailsOptionPermissionEnum["LEVEL_4"] = "LEVEL_4";
  AccountPositionDetailsOptionPermissionEnum["LEVEL_7"] = "LEVEL_7";
  AccountPositionDetailsOptionPermissionEnum["LEVEL_9"] = "LEVEL_9";
  AccountPositionDetailsOptionPermissionEnum["LEGACY_OPTION_PERMISSION"] = "LEGACY_OPTION_PERMISSION";
})(AccountPositionDetailsOptionPermissionEnum = exports.AccountPositionDetailsOptionPermissionEnum || (exports.AccountPositionDetailsOptionPermissionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsAccountStatusEnum;
(function (AccountPositionDetailsAccountStatusEnum) {
  AccountPositionDetailsAccountStatusEnum["OPEN"] = "OPEN";
  AccountPositionDetailsAccountStatusEnum["ABANDONED"] = "ABANDONED";
  AccountPositionDetailsAccountStatusEnum["ESCHEATED"] = "ESCHEATED";
  AccountPositionDetailsAccountStatusEnum["CLOSED_NO_POSITIONS"] = "CLOSED_NO_POSITIONS";
  AccountPositionDetailsAccountStatusEnum["CLOSED_WITH_NON_TRANSFERABLES"] = "CLOSED_WITH_NON_TRANSFERABLES";
  AccountPositionDetailsAccountStatusEnum["ABANDONED_NO_POSITIONS"] = "ABANDONED_NO_POSITIONS";
  AccountPositionDetailsAccountStatusEnum["ABANDONED_WITH_NON_TRANSFERABLES"] = "ABANDONED_WITH_NON_TRANSFERABLES";
  AccountPositionDetailsAccountStatusEnum["RPO"] = "RPO";
  AccountPositionDetailsAccountStatusEnum["PENDING_CLOSE"] = "PENDING_CLOSE";
})(AccountPositionDetailsAccountStatusEnum = exports.AccountPositionDetailsAccountStatusEnum || (exports.AccountPositionDetailsAccountStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsOptionIncomeRangeEnum;
(function (AccountPositionDetailsOptionIncomeRangeEnum) {
  AccountPositionDetailsOptionIncomeRangeEnum["LESS_THAN_20000"] = "LESS_THAN_20000";
  AccountPositionDetailsOptionIncomeRangeEnum["BETWEEN_20000_TO_49999"] = "BETWEEN_20000_TO_49999";
  AccountPositionDetailsOptionIncomeRangeEnum["BETWEEN_50000_TO_74999"] = "BETWEEN_50000_TO_74999";
  AccountPositionDetailsOptionIncomeRangeEnum["BETWEEN_75000_TO_99999"] = "BETWEEN_75000_TO_99999";
  AccountPositionDetailsOptionIncomeRangeEnum["BETWEEN_100000_TO_499999"] = "BETWEEN_100000_TO_499999";
  AccountPositionDetailsOptionIncomeRangeEnum["BETWEEN_500000_AND_ABOVE"] = "BETWEEN_500000_AND_ABOVE";
})(AccountPositionDetailsOptionIncomeRangeEnum = exports.AccountPositionDetailsOptionIncomeRangeEnum || (exports.AccountPositionDetailsOptionIncomeRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsOptionLiquidityRangeEnum;
(function (AccountPositionDetailsOptionLiquidityRangeEnum) {
  AccountPositionDetailsOptionLiquidityRangeEnum["LESS_THAN_20000"] = "LESS_THAN_20000";
  AccountPositionDetailsOptionLiquidityRangeEnum["BETWEEN_20000_TO_49999"] = "BETWEEN_20000_TO_49999";
  AccountPositionDetailsOptionLiquidityRangeEnum["BETWEEN_50000_TO_74999"] = "BETWEEN_50000_TO_74999";
  AccountPositionDetailsOptionLiquidityRangeEnum["BETWEEN_75000_TO_99999"] = "BETWEEN_75000_TO_99999";
  AccountPositionDetailsOptionLiquidityRangeEnum["BETWEEN_100000_TO_499999"] = "BETWEEN_100000_TO_499999";
  AccountPositionDetailsOptionLiquidityRangeEnum["BETWEEN_500000_AND_ABOVE"] = "BETWEEN_500000_AND_ABOVE";
})(AccountPositionDetailsOptionLiquidityRangeEnum = exports.AccountPositionDetailsOptionLiquidityRangeEnum || (exports.AccountPositionDetailsOptionLiquidityRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsOptionTotalRangeEnum;
(function (AccountPositionDetailsOptionTotalRangeEnum) {
  AccountPositionDetailsOptionTotalRangeEnum["LESS_THAN_20000"] = "LESS_THAN_20000";
  AccountPositionDetailsOptionTotalRangeEnum["BETWEEN_20000_TO_49999"] = "BETWEEN_20000_TO_49999";
  AccountPositionDetailsOptionTotalRangeEnum["BETWEEN_50000_TO_74999"] = "BETWEEN_50000_TO_74999";
  AccountPositionDetailsOptionTotalRangeEnum["BETWEEN_75000_TO_99999"] = "BETWEEN_75000_TO_99999";
  AccountPositionDetailsOptionTotalRangeEnum["BETWEEN_100000_TO_499999"] = "BETWEEN_100000_TO_499999";
  AccountPositionDetailsOptionTotalRangeEnum["BETWEEN_500000_AND_ABOVE"] = "BETWEEN_500000_AND_ABOVE";
})(AccountPositionDetailsOptionTotalRangeEnum = exports.AccountPositionDetailsOptionTotalRangeEnum || (exports.AccountPositionDetailsOptionTotalRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var AccountPositionDetailsOptionTradingObjectiveEnum;
(function (AccountPositionDetailsOptionTradingObjectiveEnum) {
  AccountPositionDetailsOptionTradingObjectiveEnum["INCOME"] = "INCOME";
  AccountPositionDetailsOptionTradingObjectiveEnum["HEDGING"] = "HEDGING";
  AccountPositionDetailsOptionTradingObjectiveEnum["SPECULATION"] = "SPECULATION";
  AccountPositionDetailsOptionTradingObjectiveEnum["INCOME_HEDGING_SPECULATION"] = "INCOME_HEDGING_SPECULATION";
  AccountPositionDetailsOptionTradingObjectiveEnum["INCOME_HEDGING"] = "INCOME_HEDGING";
  AccountPositionDetailsOptionTradingObjectiveEnum["INCOME_SPECULATION"] = "INCOME_SPECULATION";
  AccountPositionDetailsOptionTradingObjectiveEnum["HEDGING_SPECULATION"] = "HEDGING_SPECULATION";
})(AccountPositionDetailsOptionTradingObjectiveEnum = exports.AccountPositionDetailsOptionTradingObjectiveEnum || (exports.AccountPositionDetailsOptionTradingObjectiveEnum = {}));
