"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestrictionLevels = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var RestrictionLevels;
(function (RestrictionLevels) {
  RestrictionLevels["CLIENT"] = "CLIENT";
  RestrictionLevels["ACCOUNT"] = "ACCOUNT";
  RestrictionLevels["POSITION"] = "POSITION";
})(RestrictionLevels = exports.RestrictionLevels || (exports.RestrictionLevels = {}));
