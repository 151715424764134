"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GrantedRolesRoleSubTypeEnum = exports.GrantedRolesAccessLevelEnum = exports.GrantedRolesRoleTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var GrantedRolesRoleTypeEnum;
(function (GrantedRolesRoleTypeEnum) {
  GrantedRolesRoleTypeEnum["VINA"] = "vina";
  GrantedRolesRoleTypeEnum["AA"] = "AA";
  GrantedRolesRoleTypeEnum["AC"] = "AC";
  GrantedRolesRoleTypeEnum["AD"] = "AD";
  GrantedRolesRoleTypeEnum["ADMN"] = "ADMN";
  GrantedRolesRoleTypeEnum["AG"] = "AG";
  GrantedRolesRoleTypeEnum["AI"] = "AI";
  GrantedRolesRoleTypeEnum["AL"] = "AL";
  GrantedRolesRoleTypeEnum["AN"] = "AN";
  GrantedRolesRoleTypeEnum["AOAD"] = "AOAD";
  GrantedRolesRoleTypeEnum["AOSI"] = "AOSI";
  GrantedRolesRoleTypeEnum["AOTB"] = "AOTB";
  GrantedRolesRoleTypeEnum["AS"] = "AS";
  GrantedRolesRoleTypeEnum["ASP"] = "ASP";
  GrantedRolesRoleTypeEnum["GASF"] = "GASF";
  GrantedRolesRoleTypeEnum["ASPT"] = "ASPT";
  GrantedRolesRoleTypeEnum["ASSC"] = "ASSC";
  GrantedRolesRoleTypeEnum["AT"] = "AT";
  GrantedRolesRoleTypeEnum["ATSA"] = "ATSA";
  GrantedRolesRoleTypeEnum["AU"] = "AU";
  GrantedRolesRoleTypeEnum["AUOF"] = "AUOF";
  GrantedRolesRoleTypeEnum["AZCA"] = "AZCA";
  GrantedRolesRoleTypeEnum["AZCS"] = "AZCS";
  GrantedRolesRoleTypeEnum["AZMG"] = "AZMG";
  GrantedRolesRoleTypeEnum["AZNA"] = "AZNA";
  GrantedRolesRoleTypeEnum["AZOR"] = "AZOR";
  GrantedRolesRoleTypeEnum["BE"] = "BE";
  GrantedRolesRoleTypeEnum["BEAL"] = "BEAL";
  GrantedRolesRoleTypeEnum["BEBE"] = "BEBE";
  GrantedRolesRoleTypeEnum["BK"] = "BK";
  GrantedRolesRoleTypeEnum["BL"] = "BL";
  GrantedRolesRoleTypeEnum["BN"] = "BN";
  GrantedRolesRoleTypeEnum["BNAL"] = "BNAL";
  GrantedRolesRoleTypeEnum["BNBN"] = "BNBN";
  GrantedRolesRoleTypeEnum["BO"] = "BO";
  GrantedRolesRoleTypeEnum["BR"] = "BR";
  GrantedRolesRoleTypeEnum["GASD"] = "GASD";
  GrantedRolesRoleTypeEnum["BRKR"] = "BRKR";
  GrantedRolesRoleTypeEnum["BSSA"] = "BSSA";
  GrantedRolesRoleTypeEnum["BT"] = "BT";
  GrantedRolesRoleTypeEnum["BU"] = "BU";
  GrantedRolesRoleTypeEnum["CABN"] = "CABN";
  GrantedRolesRoleTypeEnum["CANN"] = "CANN";
  GrantedRolesRoleTypeEnum["CASC"] = "CASC";
  GrantedRolesRoleTypeEnum["CC"] = "CC";
  GrantedRolesRoleTypeEnum["CCNS"] = "CCNS";
  GrantedRolesRoleTypeEnum["CE"] = "CE";
  GrantedRolesRoleTypeEnum["CFRM"] = "CFRM";
  GrantedRolesRoleTypeEnum["CG"] = "CG";
  GrantedRolesRoleTypeEnum["CH"] = "CH";
  GrantedRolesRoleTypeEnum["CL"] = "CL";
  GrantedRolesRoleTypeEnum["CLHS"] = "CLHS";
  GrantedRolesRoleTypeEnum["CLNT"] = "CLNT";
  GrantedRolesRoleTypeEnum["CLSA"] = "CLSA";
  GrantedRolesRoleTypeEnum["CM"] = "CM";
  GrantedRolesRoleTypeEnum["CO"] = "CO";
  GrantedRolesRoleTypeEnum["COBN"] = "COBN";
  GrantedRolesRoleTypeEnum["CONS"] = "CONS";
  GrantedRolesRoleTypeEnum["COTR"] = "COTR";
  GrantedRolesRoleTypeEnum["CP"] = "CP";
  GrantedRolesRoleTypeEnum["CR"] = "CR";
  GrantedRolesRoleTypeEnum["CREW"] = "CREW";
  GrantedRolesRoleTypeEnum["CS"] = "CS";
  GrantedRolesRoleTypeEnum["CU"] = "CU";
  GrantedRolesRoleTypeEnum["CUAL"] = "CUAL";
  GrantedRolesRoleTypeEnum["CUCM"] = "CUCM";
  GrantedRolesRoleTypeEnum["CUCO"] = "CUCO";
  GrantedRolesRoleTypeEnum["CUCU"] = "CUCU";
  GrantedRolesRoleTypeEnum["CUEA"] = "CUEA";
  GrantedRolesRoleTypeEnum["CUGU"] = "CUGU";
  GrantedRolesRoleTypeEnum["CULT"] = "CULT";
  GrantedRolesRoleTypeEnum["CUNM"] = "CUNM";
  GrantedRolesRoleTypeEnum["CUPA"] = "CUPA";
  GrantedRolesRoleTypeEnum["CUPL"] = "CUPL";
  GrantedRolesRoleTypeEnum["CUSD"] = "CUSD";
  GrantedRolesRoleTypeEnum["CUSN"] = "CUSN";
  GrantedRolesRoleTypeEnum["CUUS"] = "CUUS";
  GrantedRolesRoleTypeEnum["DC"] = "DC";
  GrantedRolesRoleTypeEnum["DDLC"] = "DDLC";
  GrantedRolesRoleTypeEnum["DECD"] = "DECD";
  GrantedRolesRoleTypeEnum["DL"] = "DL";
  GrantedRolesRoleTypeEnum["DLYC"] = "DLYC";
  GrantedRolesRoleTypeEnum["DP"] = "DP";
  GrantedRolesRoleTypeEnum["DR"] = "DR";
  GrantedRolesRoleTypeEnum["DS"] = "DS";
  GrantedRolesRoleTypeEnum["DSRC"] = "DSRC";
  GrantedRolesRoleTypeEnum["DV"] = "DV";
  GrantedRolesRoleTypeEnum["EA"] = "EA";
  GrantedRolesRoleTypeEnum["EC"] = "EC";
  GrantedRolesRoleTypeEnum["ECON"] = "ECON";
  GrantedRolesRoleTypeEnum["EE"] = "EE";
  GrantedRolesRoleTypeEnum["EFTA"] = "EFTA";
  GrantedRolesRoleTypeEnum["EMPL"] = "EMPL";
  GrantedRolesRoleTypeEnum["EMPT"] = "EMPT";
  GrantedRolesRoleTypeEnum["ER"] = "ER";
  GrantedRolesRoleTypeEnum["EX"] = "EX";
  GrantedRolesRoleTypeEnum["EXAD"] = "EXAD";
  GrantedRolesRoleTypeEnum["EXAL"] = "EXAL";
  GrantedRolesRoleTypeEnum["EXEX"] = "EXEX";
  GrantedRolesRoleTypeEnum["FA"] = "FA";
  GrantedRolesRoleTypeEnum["FD"] = "FD";
  GrantedRolesRoleTypeEnum["FI"] = "FI";
  GrantedRolesRoleTypeEnum["FL"] = "FL";
  GrantedRolesRoleTypeEnum["FPOA"] = "FPOA";
  GrantedRolesRoleTypeEnum["FR"] = "FR";
  GrantedRolesRoleTypeEnum["FRDA"] = "FRDA";
  GrantedRolesRoleTypeEnum["FRDB"] = "FRDB";
  GrantedRolesRoleTypeEnum["FRDC"] = "FRDC";
  GrantedRolesRoleTypeEnum["FSRS"] = "FSRS";
  GrantedRolesRoleTypeEnum["FTRA"] = "FTRA";
  GrantedRolesRoleTypeEnum["FTRB"] = "FTRB";
  GrantedRolesRoleTypeEnum["FTRC"] = "FTRC";
  GrantedRolesRoleTypeEnum["GC"] = "GC";
  GrantedRolesRoleTypeEnum["GD"] = "GD";
  GrantedRolesRoleTypeEnum["GASR"] = "GASR";
  GrantedRolesRoleTypeEnum["GASL"] = "GASL";
  GrantedRolesRoleTypeEnum["GDLA"] = "GDLA";
  GrantedRolesRoleTypeEnum["GF"] = "GF";
  GrantedRolesRoleTypeEnum["GFAA"] = "GFAA";
  GrantedRolesRoleTypeEnum["GFOA"] = "GFOA";
  GrantedRolesRoleTypeEnum["GIPR"] = "GIPR";
  GrantedRolesRoleTypeEnum["GLAA"] = "GLAA";
  GrantedRolesRoleTypeEnum["GLBL"] = "GLBL";
  GrantedRolesRoleTypeEnum["GM"] = "GM";
  GrantedRolesRoleTypeEnum["GP"] = "GP";
  GrantedRolesRoleTypeEnum["GR"] = "GR";
  GrantedRolesRoleTypeEnum["GS"] = "GS";
  GrantedRolesRoleTypeEnum["GU"] = "GU";
  GrantedRolesRoleTypeEnum["HLWL"] = "HLWL";
  GrantedRolesRoleTypeEnum["HR"] = "HR";
  GrantedRolesRoleTypeEnum["HS"] = "HS";
  GrantedRolesRoleTypeEnum["GLAS"] = "GLAS";
  GrantedRolesRoleTypeEnum["HSMB"] = "HSMB";
  GrantedRolesRoleTypeEnum["IAA"] = "IAA";
  GrantedRolesRoleTypeEnum["IABN"] = "IABN";
  GrantedRolesRoleTypeEnum["IADM"] = "IADM";
  GrantedRolesRoleTypeEnum["IBES"] = "IBES";
  GrantedRolesRoleTypeEnum["IC"] = "IC";
  GrantedRolesRoleTypeEnum["ICH"] = "ICH";
  GrantedRolesRoleTypeEnum["ICSH"] = "ICSH";
  GrantedRolesRoleTypeEnum["IEST"] = "IEST";
  GrantedRolesRoleTypeEnum["IEXE"] = "IEXE";
  GrantedRolesRoleTypeEnum["IFRA"] = "IFRA";
  GrantedRolesRoleTypeEnum["IFRM"] = "IFRM";
  GrantedRolesRoleTypeEnum["IFRS"] = "IFRS";
  GrantedRolesRoleTypeEnum["IFTS"] = "IFTS";
  GrantedRolesRoleTypeEnum["IFVW"] = "IFVW";
  GrantedRolesRoleTypeEnum["IICA"] = "IICA";
  GrantedRolesRoleTypeEnum["IICS"] = "IICS";
  GrantedRolesRoleTypeEnum["IIOB"] = "IIOB";
  GrantedRolesRoleTypeEnum["IIOD"] = "IIOD";
  GrantedRolesRoleTypeEnum["IIOF"] = "IIOF";
  GrantedRolesRoleTypeEnum["IIOG"] = "IIOG";
  GrantedRolesRoleTypeEnum["IIOO"] = "IIOO";
  GrantedRolesRoleTypeEnum["IIOP"] = "IIOP";
  GrantedRolesRoleTypeEnum["IIOS"] = "IIOS";
  GrantedRolesRoleTypeEnum["IIOU"] = "IIOU";
  GrantedRolesRoleTypeEnum["IIPA"] = "IIPA";
  GrantedRolesRoleTypeEnum["IIPM"] = "IIPM";
  GrantedRolesRoleTypeEnum["IISR"] = "IISR";
  GrantedRolesRoleTypeEnum["IL"] = "IL";
  GrantedRolesRoleTypeEnum["ILRR"] = "ILRR";
  GrantedRolesRoleTypeEnum["IMKR"] = "IMKR";
  GrantedRolesRoleTypeEnum["IMTR"] = "IMTR";
  GrantedRolesRoleTypeEnum["IN"] = "IN";
  GrantedRolesRoleTypeEnum["INAD"] = "INAD";
  GrantedRolesRoleTypeEnum["INAN"] = "INAN";
  GrantedRolesRoleTypeEnum["INCN"] = "INCN";
  GrantedRolesRoleTypeEnum["INPR"] = "INPR";
  GrantedRolesRoleTypeEnum["INSE"] = "INSE";
  GrantedRolesRoleTypeEnum["INSP"] = "INSP";
  GrantedRolesRoleTypeEnum["INTR"] = "INTR";
  GrantedRolesRoleTypeEnum["IO"] = "IO";
  GrantedRolesRoleTypeEnum["IOBN"] = "IOBN";
  GrantedRolesRoleTypeEnum["IP"] = "IP";
  GrantedRolesRoleTypeEnum["IPAG"] = "IPAG";
  GrantedRolesRoleTypeEnum["IPBT"] = "IPBT";
  GrantedRolesRoleTypeEnum["IPCM"] = "IPCM";
  GrantedRolesRoleTypeEnum["IPCN"] = "IPCN";
  GrantedRolesRoleTypeEnum["IPDB"] = "IPDB";
  GrantedRolesRoleTypeEnum["IPEF"] = "IPEF";
  GrantedRolesRoleTypeEnum["IPHR"] = "IPHR";
  GrantedRolesRoleTypeEnum["IPIA"] = "IPIA";
  GrantedRolesRoleTypeEnum["IPII"] = "IPII";
  GrantedRolesRoleTypeEnum["IPIN"] = "IPIN";
  GrantedRolesRoleTypeEnum["IPIR"] = "IPIR";
  GrantedRolesRoleTypeEnum["IPPC"] = "IPPC";
  GrantedRolesRoleTypeEnum["IPPR"] = "IPPR";
  GrantedRolesRoleTypeEnum["IPPV"] = "IPPV";
  GrantedRolesRoleTypeEnum["IPSA"] = "IPSA";
  GrantedRolesRoleTypeEnum["IPSM"] = "IPSM";
  GrantedRolesRoleTypeEnum["IPSS"] = "IPSS";
  GrantedRolesRoleTypeEnum["IQTR"] = "IQTR";
  GrantedRolesRoleTypeEnum["IR"] = "IR";
  GrantedRolesRoleTypeEnum["IREL"] = "IREL";
  GrantedRolesRoleTypeEnum["IRKH"] = "IRKH";
  GrantedRolesRoleTypeEnum["IRKM"] = "IRKM";
  GrantedRolesRoleTypeEnum["IRLR"] = "IRLR";
  GrantedRolesRoleTypeEnum["IRPS"] = "IRPS";
  GrantedRolesRoleTypeEnum["IRSM"] = "IRSM";
  GrantedRolesRoleTypeEnum["IRTR"] = "IRTR";
  GrantedRolesRoleTypeEnum["ISAF"] = "ISAF";
  GrantedRolesRoleTypeEnum["ISAS"] = "ISAS";
  GrantedRolesRoleTypeEnum["ITAD"] = "ITAD";
  GrantedRolesRoleTypeEnum["IUA"] = "IUA";
  GrantedRolesRoleTypeEnum["IVCO"] = "IVCO";
  GrantedRolesRoleTypeEnum["IWCC"] = "IWCC";
  GrantedRolesRoleTypeEnum["IWHF"] = "IWHF";
  GrantedRolesRoleTypeEnum["IWIA"] = "IWIA";
  GrantedRolesRoleTypeEnum["IWIB"] = "IWIB";
  GrantedRolesRoleTypeEnum["IWIC"] = "IWIC";
  GrantedRolesRoleTypeEnum["IWID"] = "IWID";
  GrantedRolesRoleTypeEnum["IWRA"] = "IWRA";
  GrantedRolesRoleTypeEnum["IWRB"] = "IWRB";
  GrantedRolesRoleTypeEnum["IWTP"] = "IWTP";
  GrantedRolesRoleTypeEnum["IWVN"] = "IWVN";
  GrantedRolesRoleTypeEnum["IWWA"] = "IWWA";
  GrantedRolesRoleTypeEnum["IWWB"] = "IWWB";
  GrantedRolesRoleTypeEnum["I4_AR"] = "I4AR";
  GrantedRolesRoleTypeEnum["JA"] = "JA";
  GrantedRolesRoleTypeEnum["JANN"] = "JANN";
  GrantedRolesRoleTypeEnum["JOWN"] = "JOWN";
  GrantedRolesRoleTypeEnum["JS"] = "JS";
  GrantedRolesRoleTypeEnum["JVEN"] = "JVEN";
  GrantedRolesRoleTypeEnum["LA"] = "LA";
  GrantedRolesRoleTypeEnum["LC"] = "LC";
  GrantedRolesRoleTypeEnum["LEAD"] = "LEAD";
  GrantedRolesRoleTypeEnum["LP"] = "LP";
  GrantedRolesRoleTypeEnum["LPOA"] = "LPOA";
  GrantedRolesRoleTypeEnum["LT"] = "LT";
  GrantedRolesRoleTypeEnum["LTEX"] = "LTEX";
  GrantedRolesRoleTypeEnum["LTQT"] = "LTQT";
  GrantedRolesRoleTypeEnum["MABN"] = "MABN";
  GrantedRolesRoleTypeEnum["MC"] = "MC";
  GrantedRolesRoleTypeEnum["MGMT"] = "MGMT";
  GrantedRolesRoleTypeEnum["MINR"] = "MINR";
  GrantedRolesRoleTypeEnum["ML"] = "ML";
  GrantedRolesRoleTypeEnum["MM"] = "MM";
  GrantedRolesRoleTypeEnum["MN"] = "MN";
  GrantedRolesRoleTypeEnum["MOWN"] = "MOWN";
  GrantedRolesRoleTypeEnum["MPOO"] = "MPOO";
  GrantedRolesRoleTypeEnum["MR"] = "MR";
  GrantedRolesRoleTypeEnum["NA"] = "NA";
  GrantedRolesRoleTypeEnum["NC"] = "NC";
  GrantedRolesRoleTypeEnum["NCCA"] = "NCCA";
  GrantedRolesRoleTypeEnum["NCMG"] = "NCMG";
  GrantedRolesRoleTypeEnum["NCNA"] = "NCNA";
  GrantedRolesRoleTypeEnum["NCOR"] = "NCOR";
  GrantedRolesRoleTypeEnum["NF"] = "NF";
  GrantedRolesRoleTypeEnum["NIIV"] = "NIIV";
  GrantedRolesRoleTypeEnum["NM"] = "NM";
  GrantedRolesRoleTypeEnum["NMNE"] = "NMNE";
  GrantedRolesRoleTypeEnum["NO"] = "NO";
  GrantedRolesRoleTypeEnum["NOMO"] = "NOMO";
  GrantedRolesRoleTypeEnum["NPB1"] = "NPB1";
  GrantedRolesRoleTypeEnum["NPB2"] = "NPB2";
  GrantedRolesRoleTypeEnum["NPPT"] = "NPPT";
  GrantedRolesRoleTypeEnum["NPQ1"] = "NPQ1";
  GrantedRolesRoleTypeEnum["NPQ2"] = "NPQ2";
  GrantedRolesRoleTypeEnum["NTWK"] = "NTWK";
  GrantedRolesRoleTypeEnum["OC"] = "OC";
  GrantedRolesRoleTypeEnum["OF"] = "OF";
  GrantedRolesRoleTypeEnum["OFC"] = "OFC";
  GrantedRolesRoleTypeEnum["ORGN"] = "ORGN";
  GrantedRolesRoleTypeEnum["OVRD"] = "OVRD";
  GrantedRolesRoleTypeEnum["OW"] = "OW";
  GrantedRolesRoleTypeEnum["OWNR"] = "OWNR";
  GrantedRolesRoleTypeEnum["OWNS"] = "OWNS";
  GrantedRolesRoleTypeEnum["OWTR"] = "OWTR";
  GrantedRolesRoleTypeEnum["PA"] = "PA";
  GrantedRolesRoleTypeEnum["PAAD"] = "PAAD";
  GrantedRolesRoleTypeEnum["PABN"] = "PABN";
  GrantedRolesRoleTypeEnum["PACA"] = "PACA";
  GrantedRolesRoleTypeEnum["PACS"] = "PACS";
  GrantedRolesRoleTypeEnum["PAMG"] = "PAMG";
  GrantedRolesRoleTypeEnum["PANA"] = "PANA";
  GrantedRolesRoleTypeEnum["PANN"] = "PANN";
  GrantedRolesRoleTypeEnum["PAOR"] = "PAOR";
  GrantedRolesRoleTypeEnum["PART"] = "PART";
  GrantedRolesRoleTypeEnum["PASA"] = "PASA";
  GrantedRolesRoleTypeEnum["PAVB"] = "PAVB";
  GrantedRolesRoleTypeEnum["PAYE"] = "PAYE";
  GrantedRolesRoleTypeEnum["PAYR"] = "PAYR";
  GrantedRolesRoleTypeEnum["PB"] = "PB";
  GrantedRolesRoleTypeEnum["PC"] = "PC";
  GrantedRolesRoleTypeEnum["PCTS"] = "PCTS";
  GrantedRolesRoleTypeEnum["PD"] = "PD";
  GrantedRolesRoleTypeEnum["PDAG"] = "PDAG";
  GrantedRolesRoleTypeEnum["PE"] = "PE";
  GrantedRolesRoleTypeEnum["PL"] = "PL";
  GrantedRolesRoleTypeEnum["PLAD"] = "PLAD";
  GrantedRolesRoleTypeEnum["PLTR"] = "PLTR";
  GrantedRolesRoleTypeEnum["PLVW"] = "PLVW";
  GrantedRolesRoleTypeEnum["PM"] = "PM";
  GrantedRolesRoleTypeEnum["PO"] = "PO";
  GrantedRolesRoleTypeEnum["POBN"] = "POBN";
  GrantedRolesRoleTypeEnum["POOL"] = "POOL";
  GrantedRolesRoleTypeEnum["POWN"] = "POWN";
  GrantedRolesRoleTypeEnum["PP"] = "PP";
  GrantedRolesRoleTypeEnum["PPAG"] = "PPAG";
  GrantedRolesRoleTypeEnum["PR"] = "PR";
  GrantedRolesRoleTypeEnum["PRCT"] = "PRCT";
  GrantedRolesRoleTypeEnum["PRIN"] = "PRIN";
  GrantedRolesRoleTypeEnum["PRRT"] = "PRRT";
  GrantedRolesRoleTypeEnum["PRTR"] = "PRTR";
  GrantedRolesRoleTypeEnum["PS"] = "PS";
  GrantedRolesRoleTypeEnum["PSMB"] = "PSMB";
  GrantedRolesRoleTypeEnum["PV"] = "PV";
  GrantedRolesRoleTypeEnum["RCAG"] = "RCAG";
  GrantedRolesRoleTypeEnum["RCRA"] = "RCRA";
  GrantedRolesRoleTypeEnum["RE"] = "RE";
  GrantedRolesRoleTypeEnum["REF"] = "REF";
  GrantedRolesRoleTypeEnum["RESP"] = "RESP";
  GrantedRolesRoleTypeEnum["PSGC"] = "PSGC";
  GrantedRolesRoleTypeEnum["REXU"] = "REXU";
  GrantedRolesRoleTypeEnum["RGRL"] = "RGRL";
  GrantedRolesRoleTypeEnum["RI"] = "RI";
  GrantedRolesRoleTypeEnum["RK"] = "RK";
  GrantedRolesRoleTypeEnum["RKCO"] = "RKCO";
  GrantedRolesRoleTypeEnum["RKTR"] = "RKTR";
  GrantedRolesRoleTypeEnum["RKVW"] = "RKVW";
  GrantedRolesRoleTypeEnum["RM"] = "RM";
  GrantedRolesRoleTypeEnum["RMFA"] = "RMFA";
  GrantedRolesRoleTypeEnum["RMGR"] = "RMGR";
  GrantedRolesRoleTypeEnum["RP"] = "RP";
  GrantedRolesRoleTypeEnum["RPLN"] = "RPLN";
  GrantedRolesRoleTypeEnum["SASC"] = "SASC";
  GrantedRolesRoleTypeEnum["SBAO"] = "SBAO";
  GrantedRolesRoleTypeEnum["SBOC"] = "SBOC";
  GrantedRolesRoleTypeEnum["SBPA"] = "SBPA";
  GrantedRolesRoleTypeEnum["SBPC"] = "SBPC";
  GrantedRolesRoleTypeEnum["SBPP"] = "SBPP";
  GrantedRolesRoleTypeEnum["SBPS"] = "SBPS";
  GrantedRolesRoleTypeEnum["SBTP"] = "SBTP";
  GrantedRolesRoleTypeEnum["SBTR"] = "SBTR";
  GrantedRolesRoleTypeEnum["SC"] = "SC";
  GrantedRolesRoleTypeEnum["SCSA"] = "SCSA";
  GrantedRolesRoleTypeEnum["SD"] = "SD";
  GrantedRolesRoleTypeEnum["SE"] = "SE";
  GrantedRolesRoleTypeEnum["SEGM"] = "SEGM";
  GrantedRolesRoleTypeEnum["SF"] = "SF";
  GrantedRolesRoleTypeEnum["SG"] = "SG";
  GrantedRolesRoleTypeEnum["SGAC"] = "SGAC";
  GrantedRolesRoleTypeEnum["SGAM"] = "SGAM";
  GrantedRolesRoleTypeEnum["SGAO"] = "SGAO";
  GrantedRolesRoleTypeEnum["SGAT"] = "SGAT";
  GrantedRolesRoleTypeEnum["SGBC"] = "SGBC";
  GrantedRolesRoleTypeEnum["SGCB"] = "SGCB";
  GrantedRolesRoleTypeEnum["SGCC"] = "SGCC";
  GrantedRolesRoleTypeEnum["SGCF"] = "SGCF";
  GrantedRolesRoleTypeEnum["SGFP"] = "SGFP";
  GrantedRolesRoleTypeEnum["SGIA"] = "SGIA";
  GrantedRolesRoleTypeEnum["SGIB"] = "SGIB";
  GrantedRolesRoleTypeEnum["SGIO"] = "SGIO";
  GrantedRolesRoleTypeEnum["SGIP"] = "SGIP";
  GrantedRolesRoleTypeEnum["SGOT"] = "SGOT";
  GrantedRolesRoleTypeEnum["SGPB"] = "SGPB";
  GrantedRolesRoleTypeEnum["SGPD"] = "SGPD";
  GrantedRolesRoleTypeEnum["SGQR"] = "SGQR";
  GrantedRolesRoleTypeEnum["SGRM"] = "SGRM";
  GrantedRolesRoleTypeEnum["SGSC"] = "SGSC";
  GrantedRolesRoleTypeEnum["SGTM"] = "SGTM";
  GrantedRolesRoleTypeEnum["SGTS"] = "SGTS";
  GrantedRolesRoleTypeEnum["SGTT"] = "SGTT";
  GrantedRolesRoleTypeEnum["SGTX"] = "SGTX";
  GrantedRolesRoleTypeEnum["SH"] = "SH";
  GrantedRolesRoleTypeEnum["SHAL"] = "SHAL";
  GrantedRolesRoleTypeEnum["SHBN"] = "SHBN";
  GrantedRolesRoleTypeEnum["SHGP"] = "SHGP";
  GrantedRolesRoleTypeEnum["SHJS"] = "SHJS";
  GrantedRolesRoleTypeEnum["SHLP"] = "SHLP";
  GrantedRolesRoleTypeEnum["SHMN"] = "SHMN";
  GrantedRolesRoleTypeEnum["SHNO"] = "SHNO";
  GrantedRolesRoleTypeEnum["SHPS"] = "SHPS";
  GrantedRolesRoleTypeEnum["SHRM"] = "SHRM";
  GrantedRolesRoleTypeEnum["SHSH"] = "SHSH";
  GrantedRolesRoleTypeEnum["SL"] = "SL";
  GrantedRolesRoleTypeEnum["SM"] = "SM";
  GrantedRolesRoleTypeEnum["SN"] = "SN";
  GrantedRolesRoleTypeEnum["SNRC"] = "SNRC";
  GrantedRolesRoleTypeEnum["SO"] = "SO";
  GrantedRolesRoleTypeEnum["SOLP"] = "SOLP";
  GrantedRolesRoleTypeEnum["SP"] = "SP";
  GrantedRolesRoleTypeEnum["SPSE"] = "SPSE";
  GrantedRolesRoleTypeEnum["SR"] = "SR";
  GrantedRolesRoleTypeEnum["SRFC"] = "SRFC";
  GrantedRolesRoleTypeEnum["SS"] = "SS";
  GrantedRolesRoleTypeEnum["SSNA"] = "SSNA";
  GrantedRolesRoleTypeEnum["SX02"] = "SX02";
  GrantedRolesRoleTypeEnum["SX40"] = "SX40";
  GrantedRolesRoleTypeEnum["SX70"] = "SX70";
  GrantedRolesRoleTypeEnum["SX80"] = "SX80";
  GrantedRolesRoleTypeEnum["SX90"] = "SX90";
  GrantedRolesRoleTypeEnum["SX91"] = "SX91";
  GrantedRolesRoleTypeEnum["SX92"] = "SX92";
  GrantedRolesRoleTypeEnum["TC"] = "TC";
  GrantedRolesRoleTypeEnum["TE"] = "TE";
  GrantedRolesRoleTypeEnum["TPA"] = "TPA";
  GrantedRolesRoleTypeEnum["TPSA"] = "TPSA";
  GrantedRolesRoleTypeEnum["TR"] = "TR";
  GrantedRolesRoleTypeEnum["TRAL"] = "TRAL";
  GrantedRolesRoleTypeEnum["TRPO"] = "TRPO";
  GrantedRolesRoleTypeEnum["TRRE"] = "TRRE";
  GrantedRolesRoleTypeEnum["TRSC"] = "TRSC";
  GrantedRolesRoleTypeEnum["TRST"] = "TRST";
  GrantedRolesRoleTypeEnum["TRTR"] = "TRTR";
  GrantedRolesRoleTypeEnum["TT"] = "TT";
  GrantedRolesRoleTypeEnum["TXTO"] = "TXTO";
  GrantedRolesRoleTypeEnum["UC"] = "UC";
  GrantedRolesRoleTypeEnum["UN"] = "UN";
  GrantedRolesRoleTypeEnum["UOBT"] = "UOBT";
  GrantedRolesRoleTypeEnum["UPAG"] = "UPAG";
  GrantedRolesRoleTypeEnum["US"] = "US";
  GrantedRolesRoleTypeEnum["USDB"] = "USDB";
  GrantedRolesRoleTypeEnum["USER"] = "USER";
  GrantedRolesRoleTypeEnum["USGR"] = "USGR";
  GrantedRolesRoleTypeEnum["USRA"] = "USRA";
  GrantedRolesRoleTypeEnum["USSG"] = "USSG";
  GrantedRolesRoleTypeEnum["VERS"] = "VERS";
  GrantedRolesRoleTypeEnum["VIVP"] = "VIVP";
  GrantedRolesRoleTypeEnum["VNDR"] = "VNDR";
  GrantedRolesRoleTypeEnum["VP"] = "VP";
  GrantedRolesRoleTypeEnum["VRPA"] = "VRPA";
  GrantedRolesRoleTypeEnum["WEBC"] = "WEBC";
  GrantedRolesRoleTypeEnum["WF"] = "WF";
  GrantedRolesRoleTypeEnum["WFNC"] = "WFNC";
  GrantedRolesRoleTypeEnum["WVOA"] = "WVOA";
  GrantedRolesRoleTypeEnum["XH"] = "XH";
  GrantedRolesRoleTypeEnum["XS"] = "XS";
  GrantedRolesRoleTypeEnum["XW"] = "XW";
})(GrantedRolesRoleTypeEnum = exports.GrantedRolesRoleTypeEnum || (exports.GrantedRolesRoleTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GrantedRolesAccessLevelEnum;
(function (GrantedRolesAccessLevelEnum) {
  GrantedRolesAccessLevelEnum["WBPH"] = "WBPH";
  GrantedRolesAccessLevelEnum["PHON"] = "PHON";
  GrantedRolesAccessLevelEnum["BENE"] = "BENE";
})(GrantedRolesAccessLevelEnum = exports.GrantedRolesAccessLevelEnum || (exports.GrantedRolesAccessLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
var GrantedRolesRoleSubTypeEnum;
(function (GrantedRolesRoleSubTypeEnum) {
  GrantedRolesRoleSubTypeEnum["O"] = "O";
  GrantedRolesRoleSubTypeEnum["N"] = "N";
  GrantedRolesRoleSubTypeEnum["PAIO"] = "PAIO";
  GrantedRolesRoleSubTypeEnum["PAVC"] = "PAVC";
})(GrantedRolesRoleSubTypeEnum = exports.GrantedRolesRoleSubTypeEnum || (exports.GrantedRolesRoleSubTypeEnum = {}));
