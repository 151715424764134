"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContributionContributionYearEnum = exports.ContributionContributionSourceEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ContributionContributionSourceEnum;
(function (ContributionContributionSourceEnum) {
  ContributionContributionSourceEnum["EMPLOYER"] = "EMPLOYER";
  ContributionContributionSourceEnum["EMPLOYEE"] = "EMPLOYEE";
})(ContributionContributionSourceEnum = exports.ContributionContributionSourceEnum || (exports.ContributionContributionSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ContributionContributionYearEnum;
(function (ContributionContributionYearEnum) {
  ContributionContributionYearEnum["CURRENT_YEAR"] = "CURRENT_YEAR";
  ContributionContributionYearEnum["PRIOR_YEAR"] = "PRIOR_YEAR";
})(ContributionContributionYearEnum = exports.ContributionContributionYearEnum || (exports.ContributionContributionYearEnum = {}));
