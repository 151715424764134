import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import content from '@content/content.json';
import { CONSTANTS, ROUTES } from '@etfs-equities/constants';
import { JsonContent } from '@etfs-equities/models';
import { ExtendedHoursService } from '@etfs-equities/services/extended-hours/extended-hours.service';
import { selectExtendedTradingTabLink, selectTradeTicketTabLink, TabLink } from '@etfs-equities/store';
import { select, Store } from '@ngrx/store';
import { NavigationMenuData } from '@vg-constellation/angular-17/navigation';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'twe-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationTabsComponent implements OnInit, OnDestroy {
  content: JsonContent = content;
  hideActiveItem = false;

  navigationMenuData$: Observable<NavigationMenuData>;
  isExtendedTradeAlwaysOnEnabled$: Observable<boolean>;
  isExtendedTradingEnabled$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();
  private initNavigationTabsData$ = new BehaviorSubject<void>(null);

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly extendedHoursService: ExtendedHoursService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  ngOnInit() {
    this.isExtendedTradeAlwaysOnEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TWE_EXTENDED_TRADE_TAB_ALWAYS_ON
    );
    this.isExtendedTradingEnabled$ = this.gatekeeperService.checkSingleFeatureStatus(
      GatekeeperFeatureIds.TOGGLE_EXTENDED_TRADING
    );
    this.updateNavigationTabData();
    this.handleActiveItemChange();

    this.watchForRouteChange();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initNavigationTabsData() {
    this.navigationMenuData$ = combineLatest([
      this.store.pipe(select(selectTradeTicketTabLink)),
      this.store.pipe(select(selectExtendedTradingTabLink)),
      this.isExtendedTradingEnabled$,
      this.isExtendedTradeAlwaysOnEnabled$,
      this.extendedHoursService.isWithinEcnHours(),
    ]).pipe(
      map(
        ([tabLink, extendedTradingTabLink, isExtendedTradingEnabled, isExtendedTradeTabAlwaysOn, isWithinEcnHours]: [
          TabLink,
          TabLink,
          boolean,
          boolean,
          boolean
        ]) => {
          const tradeRoute = this.router.url.split('?')[0];
          const items = [
            {
              labelText: content.holdingsTitle,
              target: '_blank',
              routerLink: CONSTANTS.HOLDINGS_PATH,
              id: CONSTANTS.HOLDINGS_PATH,
            },
            {
              labelText: content.openOrdersTitle,
              target: '_blank',
              routerLink: CONSTANTS.OPEN_ORDERS_PATH,
              id: CONSTANTS.OPEN_ORDERS_PATH,
            },
          ];

          if (tradeRoute !== CONSTANTS.EXTENDED_TRADING_PREVIEW_PAGE_PATH) {
            items.unshift({
              labelText: content.tradeEtfTitle,
              target: '_blank',
              routerLink: tabLink?.url === CONSTANTS.CONFIRMATION_PATH ? CONSTANTS.TRADE_PATH : (tabLink.url as ROUTES),
              id: CONSTANTS.TRADE_PATH,
            });
          }

          if (
            tradeRoute !== CONSTANTS.PREVIEW_PAGE_PATH &&
            ((isExtendedTradingEnabled && isWithinEcnHours) || isExtendedTradeTabAlwaysOn)
          ) {
            items.push({
              labelText: content.extendedTrading.title,
              target: '_blank',
              routerLink:
                tabLink?.url === CONSTANTS.CONFIRMATION_PATH
                  ? CONSTANTS.EXTENDED_TRADING_PATH
                  : (extendedTradingTabLink.url as ROUTES),
              id: CONSTANTS.EXTENDED_TRADING_PATH,
            });
          }

          return { items } as NavigationMenuData;
        }
      ),
      takeUntil(this.unsubscribe$)
    );
  }

  handleActiveItemChange() {
    const path = this.router.url;

    this.hideActiveItem = !!(path === CONSTANTS.CONFIRMATION_PATH || path === CONSTANTS.CANCEL_SUCCESS_PATH);
  }

  private watchForRouteChange() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => this.handleActiveItemChange()),
        filter(
          (event: NavigationEnd) =>
            event.url === CONSTANTS.PREVIEW_PAGE_PATH ||
            event.url === CONSTANTS.EXTENDED_TRADING_PREVIEW_PAGE_PATH ||
            event.url === CONSTANTS.EXTENDED_TRADING_PATH
        ),
        tap(() => {
          this.initNavigationTabsData$.next();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private updateNavigationTabData = () => {
    this.initNavigationTabsData$
      .pipe(
        tap(() => this.initNavigationTabsData()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  };
}
