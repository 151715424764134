import { HttpErrorResponse } from '@angular/common/http';
import { CostBasisEnums } from '@app/etfs-equities/enums';
import { CostBasis } from '@app/etfs-equities/models';
import { Holding } from '@app/etfs-equities/models/account.model';
import { CostBasisTradeMethod, CostBasisTradeMethods } from '@app/etfs-equities/models/cost-basis.model';
import { CostBasisUtil } from '@app/etfs-equities/utils/cost-basis/cost-basis.util';
import { createSelector } from '@ngrx/store';
import { CostBasisMethod } from '@vanguard/trade-ui-components-lib-ng-17';

import { CostBasisState, TayneState } from '../../states';
import { selectSelectedAccount } from '../account/account.selectors';
import { selectTayneState } from '../tayne/tayne.selectors';
import { selectPositionForCurrentQuoteBySecurityType } from '../trade-ticket/trade-ticket.selectors';
import { selectTradeTicketCostBasisMethod, selectUpdateDefaultCostBasis } from '../trade-ticket/trade-ticket.selectors';

export const selectCostBasisState = createSelector(selectTayneState, (state: TayneState) => state.costBasis);

export const selectCostBasisLots = createSelector(selectCostBasisState, (state: CostBasisState): CostBasis.Lot[] => {
  if (!state.lots || !Array.isArray(state.lots.coveredLots) || !Array.isArray(state.lots.nonCoveredLots)) {
    return [];
  }

  const coveredLots = state.lots.coveredLots.map((lot) => ({ ...lot, covered: true }));
  const nonCoveredLots = state.lots.nonCoveredLots.map((lot) => ({ ...lot, covered: false }));

  return coveredLots.concat(nonCoveredLots);
});

export const selectCostBasisLotsDate = createSelector(selectCostBasisState, (state: CostBasisState) => {
  return state.lots?.asOfDate ?? '';
});

export const selectHasWashSaleLot = createSelector(
  selectCostBasisLots,
  (state: CostBasis.Lot[]) => state.filter((lot) => lot.hasUnrealizedLotWashSale).length > 0
);

export const selectLongTermCostBasisLots = createSelector(
  selectCostBasisLots,
  (state: CostBasis.Lot[]): CostBasis.Lot[] => state.filter((lot) => lot.term === CostBasisEnums.LotTerms.LONG)
);

export const selectShortTermCostBasisLots = createSelector(
  selectCostBasisLots,
  (state: CostBasis.Lot[]): CostBasis.Lot[] => state.filter((lot) => lot.term === CostBasisEnums.LotTerms.SHORT)
);

export const selectLotForm = createSelector(
  selectCostBasisState,
  (state: CostBasisState): { [key: string]: any } => state.lotForm || {}
);

export const selectSelectedLots = createSelector(
  selectCostBasisLots,
  selectLotForm,
  (lots: CostBasis.Lot[], lotForm: { [key: string]: any }) => CostBasisUtil.makeSelectedLotsFromLotForm(lots, lotForm)
);

export const selectCostBasisTradeMethods = createSelector(
  selectCostBasisState,
  (state: CostBasisState): CostBasisTradeMethods | null => {
    return state.tradeMethods;
  }
);

export const selectTradeMethodsForCurrentPositionObj = createSelector(
  selectCostBasisTradeMethods,
  selectPositionForCurrentQuoteBySecurityType,
  selectSelectedAccount,
  (tradeMethods: CostBasisTradeMethods, position: Holding, account): CostBasisTradeMethod | null =>
    position && account && tradeMethods && tradeMethods[`${account.accountId}_${position.positionId}`]
      ? tradeMethods[`${account.accountId}_${position.positionId}`]
      : null
);

export const selectTradeMethodsForCurrentPosition = createSelector(
  selectTradeMethodsForCurrentPositionObj,
  (tradeMethod: CostBasisTradeMethod): CostBasisMethod[] | null => {
    const order = [
      CostBasisMethod.MIN_TAX,
      CostBasisMethod.HIFO,
      CostBasisMethod.FIFO,
      CostBasisMethod.AVG_COST,
      CostBasisMethod.SPEC_ID,
    ];

    if (!tradeMethod?.tradeMethods?.length) {
      return null;
    }

    return [...tradeMethod.tradeMethods].sort((a, b) => {
      return order.indexOf(a) - order.indexOf(b);
    });
  }
);

export const selectPreSelectedMethodForCurrentPosition = createSelector(
  selectTradeMethodsForCurrentPositionObj,
  (tradeMethod: CostBasisTradeMethod): CostBasisMethod | null => {
    return tradeMethod?.preSelectedMethod || null;
  }
);

export const selectCostBasisTradeMethodsError = createSelector(
  selectCostBasisState,
  (state: CostBasisState): HttpErrorResponse | null => {
    return state.tradeMethodsError;
  }
);

export const selectIsEditCostBasisState = createSelector(
  selectCostBasisState,
  (state: CostBasisState) => state.isEditCostBasis
);

export const selectCostBasisForSelectedAccount = createSelector(
  selectCostBasisState,
  selectSelectedAccount,
  (state: CostBasisState, selectedAccount) => {
    return state.accounts.find((account) => account?.accountId === selectedAccount?.accountId);
  }
);

export const selectCostBasisMethodByPosition = createSelector(
  selectPositionForCurrentQuoteBySecurityType,
  selectCostBasisForSelectedAccount,
  (position, selectedAccountCostBasisData) => {
    const holdingId = position?.positionId;
    const selectedHolding = selectedAccountCostBasisData?.holdings?.find(
      (holding) => holding.holdingIdentifier === holdingId
    );
    if (holdingId && selectedHolding) {
      return selectedHolding.costBasisMethodCode;
    }
    return null;
  }
);

export const selectClientSelectedDefaultMethodForCurrentPosition = createSelector(
  selectCostBasisForSelectedAccount,
  selectPositionForCurrentQuoteBySecurityType,
  (selectedAccountCostBasisData, position) => {
    const holdingId = position?.positionId;
    const selectedHolding = selectedAccountCostBasisData?.holdings?.find(
      (holding): boolean => holding.holdingIdentifier === holdingId
    );
    if (holdingId && selectedHolding) {
      let clientSelectedDefaultMethod = selectedHolding.clientSelectedDefaultMethod;
      return clientSelectedDefaultMethod;
    }
    return false;
  }
);

export const selectSetCostBasisMethodForFutureTrades = createSelector(
  selectClientSelectedDefaultMethodForCurrentPosition,
  selectUpdateDefaultCostBasis,
  selectCostBasisMethodByPosition,
  selectTradeTicketCostBasisMethod,
  (
    clientSelectDefaultMethod,
    updateDefaultCostBasis,
    previouslyHeldCostBasisMethod,
    currentCostbasisMethod
  ): boolean => {
    if (updateDefaultCostBasis) {
      // if clientSelectDefaultMethod has not been set, we always update the current default cost basis method
      // if client has a default costbasis method, make sure they are not the same
      return !clientSelectDefaultMethod || previouslyHeldCostBasisMethod !== currentCostbasisMethod;
    }

    // default to false if the above conditions are not met
    return false;
  }
);

export const selectCostBasisEligible = createSelector(selectCostBasisForSelectedAccount, (state) => {
  return state?.tradeCostBasisEligible || false;
});

export const selectCostBasisStateErrors = createSelector(selectCostBasisState, (state) => state.tradeMethodsError);
