import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { JsonContent, Order } from '@app/etfs-equities/models';
import { SubDollarCurrency } from '@app/etfs-equities/pipes';
import { selectOrder, TayneState } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import {
  ProductDetailsTable,
  ProductDetailsTableCellContentType,
  ProductDetailsTableRowType,
} from '@vanguard/trade-ui-components-lib-ng-17';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-ecb-order-summary',
  templateUrl: './ecb-order-summary.component.html',
  styleUrl: './ecb-order-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EcbOrderSummaryComponent implements OnInit {
  //  Public variables...
  content: JsonContent = content;

  // Observable variables...
  order$: Observable<Order.Order>;

  constructor(private readonly store: Store<TayneState>, private readonly subDollarCurrency: SubDollarCurrency) {}

  ngOnInit(): void {
    this.order$ = this.store.select(selectOrder);
  }

  getEcbOrderSummaryData(view): ProductDetailsTable {
    return {
      rows: [
        {
          cell: {
            label: content.labels.executedAmount,
            content: this.subDollarCurrency.transform(view.order?.executedPrice * view.order?.shares) || '$—',
            contentType: ProductDetailsTableCellContentType.TEXT,
          },
          rowType: ProductDetailsTableRowType.SIDE_BY_SIDE_CELL_ROW,
        },
      ],
    };
  }
}
