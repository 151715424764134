"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchResultTypeEnum = exports.SearchResultCategoryEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var SearchResultCategoryEnum;
(function (SearchResultCategoryEnum) {
  SearchResultCategoryEnum["VGFUND"] = "vgfund";
  SearchResultCategoryEnum["NONVGFUND"] = "nonvgfund";
  SearchResultCategoryEnum["VGETF"] = "vgetf";
  SearchResultCategoryEnum["NONVGETF"] = "nonvgetf";
  SearchResultCategoryEnum["STOCK"] = "stock";
})(SearchResultCategoryEnum = exports.SearchResultCategoryEnum || (exports.SearchResultCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
var SearchResultTypeEnum;
(function (SearchResultTypeEnum) {
  SearchResultTypeEnum["TERM"] = "term";
  SearchResultTypeEnum["SECURITIES"] = "securities";
  SearchResultTypeEnum["OTHER_FUNDS"] = "otherFunds";
  SearchResultTypeEnum["FUND"] = "fund";
})(SearchResultTypeEnum = exports.SearchResultTypeEnum || (exports.SearchResultTypeEnum = {}));
