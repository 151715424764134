"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExchangeVgaRetirementContributionInstructionsTaxYearEnum = exports.ExchangeVgaRetirementContributionInstructionsContributionSourceEnum = exports.ExchangeVgaRetirementContributionInstructionsContributionTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ExchangeVgaRetirementContributionInstructionsContributionTypeEnum;
(function (ExchangeVgaRetirementContributionInstructionsContributionTypeEnum) {
  ExchangeVgaRetirementContributionInstructionsContributionTypeEnum["CONTRIBUTION"] = "CONTRIBUTION";
  ExchangeVgaRetirementContributionInstructionsContributionTypeEnum["ROLLOVER"] = "ROLLOVER";
  ExchangeVgaRetirementContributionInstructionsContributionTypeEnum["CONVERSION"] = "CONVERSION";
})(ExchangeVgaRetirementContributionInstructionsContributionTypeEnum = exports.ExchangeVgaRetirementContributionInstructionsContributionTypeEnum || (exports.ExchangeVgaRetirementContributionInstructionsContributionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ExchangeVgaRetirementContributionInstructionsContributionSourceEnum;
(function (ExchangeVgaRetirementContributionInstructionsContributionSourceEnum) {
  ExchangeVgaRetirementContributionInstructionsContributionSourceEnum["EMPLOYER"] = "EMPLOYER";
  ExchangeVgaRetirementContributionInstructionsContributionSourceEnum["INDIVIDUAL"] = "INDIVIDUAL";
})(ExchangeVgaRetirementContributionInstructionsContributionSourceEnum = exports.ExchangeVgaRetirementContributionInstructionsContributionSourceEnum || (exports.ExchangeVgaRetirementContributionInstructionsContributionSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ExchangeVgaRetirementContributionInstructionsTaxYearEnum;
(function (ExchangeVgaRetirementContributionInstructionsTaxYearEnum) {
  ExchangeVgaRetirementContributionInstructionsTaxYearEnum["PRIOR"] = "PRIOR";
  ExchangeVgaRetirementContributionInstructionsTaxYearEnum["CURRENT"] = "CURRENT";
})(ExchangeVgaRetirementContributionInstructionsTaxYearEnum = exports.ExchangeVgaRetirementContributionInstructionsTaxYearEnum || (exports.ExchangeVgaRetirementContributionInstructionsTaxYearEnum = {}));
