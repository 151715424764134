import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdobeAnalyticsService } from '@app/core/services';
import { CashMarginIndicatorTypes, OrderEnums } from '@app/etfs-equities/enums';
import { TransactionTypes } from '@app/etfs-equities/enums/order.enums';
import { Account, JsonContent } from '@app/etfs-equities/models';
import { TradeTicketService, WindowService } from '@app/etfs-equities/services';
import { selectAccountIsMargin, selectSelectedAccount, TayneState } from '@app/etfs-equities/store';
import { selectIsIncapacitated } from '@app/etfs-equities/store/selectors/client-data/client-data.selectors';
import { OrderUtil } from '@app/etfs-equities/utils';
import content from '@content/content.json';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, pairwise, Subject, takeUntil, withLatestFrom } from 'rxjs';

@Component({
  selector: 'twe-transaction-type-control',
  templateUrl: './transaction-type-control.component.html',
  styleUrls: ['./transaction-type-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransactionTypeControlComponent implements OnInit, OnDestroy {
  selectedAccount$: Observable<Account.Account>;

  accountIsMargin$: Observable<boolean>;

  isIncapacitated$: Observable<boolean>;

  transactionTypes = OrderEnums.TransactionTypes;

  content: JsonContent = content;

  prefilledAccountId: number | null;

  isBeacon = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public tradeTicketService: TradeTicketService,
    private readonly store: Store<TayneState>,
    private readonly route: ActivatedRoute,
    private readonly adobeService: AdobeAnalyticsService,
    private readonly windowService: WindowService
  ) {}

  ngOnInit() {
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
    this.accountIsMargin$ = this.store.pipe(select(selectAccountIsMargin));
    this.isIncapacitated$ = this.store.pipe(select(selectIsIncapacitated));

    this.isBeacon = this.windowService.getIsBeacon();

    this.watchForMarginToNonMarginAccount();
    this.prefillTransactionTypeFromRoute();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  checkPrefillParamsAndTransactionType(transactionType: string, isMargin = false): void {
    if (this.prefilledAccountId && transactionType && !this.tradeTicketService.getOrderAction()) {
      const action = OrderUtil.mapTransactionTypeFromUrlParams(transactionType);

      if (this.isAbleToPreselectTransactionType(action, isMargin)) {
        this.tradeTicketService.setOrderAction(action);
      }
    }
    this.prefilledAccountId = null;
  }

  isAbleToPreselectTransactionType(transactionType: TransactionTypes, isMargin = false): boolean {
    return (
      transactionType &&
      (isMargin ||
        transactionType === OrderEnums.TransactionTypes.BUY ||
        transactionType === OrderEnums.TransactionTypes.SELL)
    );
  }

  private watchForMarginToNonMarginAccount() {
    this.selectedAccount$
      .pipe(
        pairwise(),
        withLatestFrom(
          this.route.queryParamMap.pipe(map((params) => params.get('transactionType'))),
          this.accountIsMargin$
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([[prev, curr], transactionType, isMargin]) => {
        if (
          ((!prev && curr.marginCode === CashMarginIndicatorTypes.MARGIN) ||
            (!!prev && prev.marginCode !== curr?.marginCode)) &&
          (!curr || curr.accountId !== this.prefilledAccountId)
        ) {
          this.tradeTicketService.tradeTicket.controls.action.setValue(null);
          this.tradeTicketService.tradeTicket.controls.action.markAsPristine();
        } else {
          // for Buy to Cover and Sell Short transaction type prefilling case
          this.checkPrefillParamsAndTransactionType(transactionType, isMargin);
        }
      });
  }

  private prefillTransactionTypeFromRoute() {
    this.route.queryParamMap
      .pipe(
        map((params) => [params.get('transactionType'), params.get('accountId')]),
        filter(([transactionType, _accountId]) => !!transactionType),
        map(([transactionType, accountId]) => {
          const action = OrderUtil.mapTransactionTypeFromUrlParams(transactionType);
          this.prefilledAccountId = +accountId || null;

          // don't set the action if there's already a value for it. We don't want to override it if it has changed
          // since we first entered the trade ticket (e.g. we are returning to the trade page via an Edit button)
          if (this.isAbleToPreselectTransactionType(action) && !this.tradeTicketService.getOrderAction()) {
            this.tradeTicketService.setOrderAction(action);
            this.adobeService.sendAdobeLaunchProcess(`equityETFTicket-capture: ${action}`);
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
