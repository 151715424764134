<div *ngIf="!crewDisclaimerMFEEnabled; else crewDisclaimerMFE" class="alert-info-wrapper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-md-10 c11n-space-stack-0_5x">
        <div class="d-flex mb-4 mb-md-0">
          <tcx-custom-icon
            [size]="'medium'"
            [name]="'info'"
            [altText]="content.altText.info"
            class="c11n-space-inline-right-1_5x"
          ></tcx-custom-icon>
          <div>
            <p tdsExpansionPanelPreview class="c11n-text-md tds-mb-0" [innerHtml]="heading"></p>
            <tds-expansion-panel #expansionPanel>
              <p
                class="c11n-text-md"
                *ngFor="let item of bodyText; let last = last"
                [ngClass]="last ? 'tds-mb-5' : 'tds-my-7'"
                [innerHtml]="item"
              ></p>
            </tds-expansion-panel>
          </div>
        </div>
      </div>
      <div class="col-2 tds-text-align-right">
        <button
          class="tds-ml-xs-8 tds-ml-m-0"
          tdsButton
          tdsButtonStyle="secondary"
          tdsButtonSize="compact"
          type="button"
          [attr.aria-pressed]="expansionPanel.isOpen"
          [attr.aria-expanded]="expansionPanel.isOpen"
          (click)="expansionPanel.toggle()"
        >
          {{ expansionPanel.isOpen ? 'Close' : 'View' }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #crewDisclaimerMFE>
  <crew-disclaimer [accountId]="(selectedAccount$ | async)?.accountId"></crew-disclaimer>
</ng-template>
