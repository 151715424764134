import { Component, ViewChild } from '@angular/core';
import { AdobeAnalyticsService } from '@app/core/services';
import { JsonContent } from '@app/etfs-equities/models';
import { createAcceptEcnAgreement } from '@app/etfs-equities/store';
import content from '@content/content.json';
import { Store } from '@ngrx/store';
import { ModalDialogComponent } from '@vg-constellation/angular-17/modal-dialog';

@Component({
  selector: 'twe-extended-hours-disclosure-modal',
  templateUrl: './extended-hours-disclosure-modal.component.html',
  styleUrls: ['./extended-hours-disclosure-modal.component.scss'],
})
export class ExtendedHoursDisclosureModalComponent {
  @ViewChild('modal')
  modal: ModalDialogComponent;

  content: JsonContent = content;

  constructor(private readonly store: Store, private readonly adobeService: AdobeAnalyticsService) {}

  acceptAgreement() {
    this.adobeService.sendAdobeTrackingOnClick('Disclosure Accept', 'button');
    this.store.dispatch(createAcceptEcnAgreement());
  }
}
