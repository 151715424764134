<ng-container
  *ngIf="{
    orderCancel: (orderCancel$ | async),
    orderSummarySection: (orderSummarySection$ | async),
  } as view"
>
  <div class="cancel-order-detail" data-testid="cancelOrderData">
    <tcx-order-summary
      [sections]="[view.orderSummarySection]"
      [footnotes]="[this.getDynamicContent(view.orderCancel)]"
    ></tcx-order-summary>
  </div>
</ng-container>
