"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VGASecurityMasterSecurityTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var VGASecurityMasterSecurityTypeEnum;
(function (VGASecurityMasterSecurityTypeEnum) {
  VGASecurityMasterSecurityTypeEnum["MONEY_MARKET_FUND"] = "MONEY_MARKET_FUND";
  VGASecurityMasterSecurityTypeEnum["COMMON_STOCK"] = "COMMON_STOCK";
  VGASecurityMasterSecurityTypeEnum["PREFERRED_STOCK"] = "PREFERRED_STOCK";
  VGASecurityMasterSecurityTypeEnum["CONVERTIBLE_PREFERRED_STOCK"] = "CONVERTIBLE_PREFERRED_STOCK";
  VGASecurityMasterSecurityTypeEnum["RIGHTS"] = "RIGHTS";
  VGASecurityMasterSecurityTypeEnum["WARRANT"] = "WARRANT";
  VGASecurityMasterSecurityTypeEnum["UNITS"] = "UNITS";
  VGASecurityMasterSecurityTypeEnum["MUTUAL_FUND"] = "MUTUAL_FUND";
  VGASecurityMasterSecurityTypeEnum["LIMITED_PARTNERSHIP"] = "LIMITED_PARTNERSHIP";
  VGASecurityMasterSecurityTypeEnum["CALL_OPTION"] = "CALL_OPTION";
  VGASecurityMasterSecurityTypeEnum["PUT_OPTION"] = "PUT_OPTION";
  VGASecurityMasterSecurityTypeEnum["BANK_CALL_OPTION"] = "BANK_CALL_OPTION";
  VGASecurityMasterSecurityTypeEnum["ESCROW"] = "ESCROW";
  VGASecurityMasterSecurityTypeEnum["CORPORATE_BOND"] = "CORPORATE_BOND";
  VGASecurityMasterSecurityTypeEnum["CONVERTIBLE_BOND"] = "CONVERTIBLE_BOND";
  VGASecurityMasterSecurityTypeEnum["MUNICIPAL_BOND"] = "MUNICIPAL_BOND";
  VGASecurityMasterSecurityTypeEnum["SHORT_TERM_PAPER"] = "SHORT_TERM_PAPER";
  VGASecurityMasterSecurityTypeEnum["BOND_UNIT"] = "BOND_UNIT";
  VGASecurityMasterSecurityTypeEnum["MUNI_ASSESSMENT_DIST"] = "MUNI_ASSESSMENT_DIST";
  VGASecurityMasterSecurityTypeEnum["US_TREASURY_BILL"] = "US_TREASURY_BILL";
  VGASecurityMasterSecurityTypeEnum["US_TREASURY_NOTE"] = "US_TREASURY_NOTE";
  VGASecurityMasterSecurityTypeEnum["US_TREASURY_BOND"] = "US_TREASURY_BOND";
  VGASecurityMasterSecurityTypeEnum["OTHER_GOVERNMENT"] = "OTHER_GOVERNMENT";
  VGASecurityMasterSecurityTypeEnum["US_TREASURY_ZERO_COUPON"] = "US_TREASURY_ZERO_COUPON";
  VGASecurityMasterSecurityTypeEnum["GOVERNMENT_MORTGAGE"] = "GOVERNMENT_MORTGAGE";
  VGASecurityMasterSecurityTypeEnum["COLLATERALIZED_MORTGAGE_OBLIGATION"] = "COLLATERALIZED_MORTGAGE_OBLIGATION";
  VGASecurityMasterSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_EQUITY"] = "WHEN_AS_AND_IF_ISSUED_EQUITY";
  VGASecurityMasterSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_BOND"] = "WHEN_AS_AND_IF_ISSUED_BOND";
  VGASecurityMasterSecurityTypeEnum["BANKERS_ACCEPTANCE"] = "BANKERS_ACCEPTANCE";
  VGASecurityMasterSecurityTypeEnum["CERTIFICATES_OF_DEPOSIT"] = "CERTIFICATES_OF_DEPOSIT";
  VGASecurityMasterSecurityTypeEnum["COMMERCIAL_PAPER"] = "COMMERCIAL_PAPER";
  VGASecurityMasterSecurityTypeEnum["FIXED_INCOME_UNITS"] = "FIXED_INCOME_UNITS";
  VGASecurityMasterSecurityTypeEnum["ETF"] = "ETF";
  VGASecurityMasterSecurityTypeEnum["VANGUARD_ETF"] = "VANGUARD_ETF";
  VGASecurityMasterSecurityTypeEnum["MISCELLANEOUS_FIXED_INCOME"] = "MISCELLANEOUS_FIXED_INCOME";
  VGASecurityMasterSecurityTypeEnum["MISCELLANEOUS_STOCK"] = "MISCELLANEOUS_STOCK";
  VGASecurityMasterSecurityTypeEnum["TAX_SHELTER"] = "TAX_SHELTER";
  VGASecurityMasterSecurityTypeEnum["UNKNOWN"] = "UNKNOWN";
})(VGASecurityMasterSecurityTypeEnum = exports.VGASecurityMasterSecurityTypeEnum || (exports.VGASecurityMasterSecurityTypeEnum = {}));
