<div aria-live="off" class="refresh-button-wrapper d-block" [class.d-flex]="!isWrap">
  <ng-content *ngIf="!isLoading && (!hasFailed || alwaysShowContent)"></ng-content>

  <div class="position-relative d-inline-block" [class.refresh-button-ml-2]="!isLoading">
    <button
      c11n-link
      type="button"
      *ngIf="!isLoading && !isComplete"
      class="c11n-text-xs"
      iconName="refresh"
      fontWeight="normal"
      iconPosition="leading"
      (click)="emitRefresh()"
      [@fadeIn]
    >
      {{ title }}
    </button>

    <div class="twe-flex-center-items" *ngIf="isLoading">
      {{ content.loading.updating }}
      <c11n-spinner size="small" [spinnerStatus]="content.loading.updating"></c11n-spinner>
    </div>

    <c11n-icon *ngIf="isComplete" [name]="'success'" [size]="'small'" class="success-icon-container"></c11n-icon>
  </div>
</div>
