<ng-container
  *ngIf="{
    order: order$ | async,
    account: selectedAccount$ | async,
    env: env$ | async,
    shouldDisplayProspectusLink: shouldDisplayProspectusLink$ | async,
    orderHasExceededFundsAvailable: orderHasExceededFundsAvailable$ | async,
    isSellingAllHeldShares: isSellingAllHeldShares$ | async,
    dollarBasedTradesEnabled: dollarBasedTradesEnabled$ | async,
    isChangeOrder: isChangeOrder$ | async,
    isEditCostBasis: isEditCostBasis$ | async,
  } as view"
>
  <div class="container-fluid" data-testid="confrm-page-wrapper">
    <div class="row">
      <div class="col-lg-5 d-print-none">
        <div class="c11n-space-stack-3x">
          <twe-funds-available></twe-funds-available>
        </div>

        <div class="c11n-space-stack-3x" *ngIf="!isBeacon">
          <div class="twe-card">
            <h2 class="twe-card-header">
              {{ content.headings.makeAnotherTrade }}
            </h2>

            <ul class="twe-unstyled-list mb-0" [attr.aria-label]="content.headings.makeAnotherTrade">
              <li class="c11n-text-sm">
                <a c11n-link fontWeight="normal" [routerLink]="TRADE_PATH">{{ content.labels.tradeEtfsOrStocks }}</a>
              </li>
              <li class="c11n-text-sm">
                <a
                  c11n-link
                  fontWeight="normal"
                  href="{{ view.env?.origin_personal }}{{ BUYSELL_ROUTER_URL }}{{ BUY_TRANSACTION }}"
                >
                  {{ content.labels.buyVanguardFunds }}
                </a>
              </li>
              <li class="c11n-text-sm">
                <a
                  c11n-link
                  fontWeight="normal"
                  href="{{ view.env?.origin_personal }}{{ BUYSELL_ROUTER_URL }}{{ SELL_TRANSACTION }}"
                >
                  {{ content.labels.sellVanguardFunds }}
                </a>
              </li>
              <li class="c11n-text-sm">
                <a
                  c11n-link
                  fontWeight="normal"
                  href="{{ view.env?.origin_personal }}{{ BUYSELL_ROUTER_URL }}{{ EXCHANGE_TRANSACTION }}"
                >
                  {{ content.labels.exchangeVanguardFunds }}
                </a>
              </li>
              <li class="c11n-text-sm">
                <a c11n-link fontWeight="normal" [attr.href]="secureSiteUrls.investHub">
                  {{ content.labels.tradeOtherBrokerageProducts }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="c11n-space-stack-5x c11n-space--force" *ngIf="!isBeacon">
          <div class="twe-card">
            <h2 class="twe-card-header" data-testid="balancesExpansionPanel">
              {{ content.headings.holdings }}
            </h2>

            <ul class="twe-unstyled-list mb-0" [attr.aria-label]="content.headings.holdings">
              <li class="c11n-text-sm">
                <a c11n-link fontWeight="normal" [attr.href]="secureSiteUrls.balancesHoldings">
                  {{ content.labels.viewMyHoldings }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-7 order-first order-lg-last">
        <div class="d-none d-print-block print-vanguard-logo">
          <img src="{{ windowService.assetsPath }}assets/svg/vanguard.svg" alt="Vanguard" />
          <hr class="print-vanguard-logo__hr" />
        </div>

        <twe-order-confirmation-header
          *ngIf="view.order"
          [accountId]="view.order.accountId"
          [orderId]="view.order.orderId"
          [confirmationNumber]="view.order.confirmationNumber"
          [isChangeOrder]="view.isChangeOrder"
        ></twe-order-confirmation-header>

        <div class="c11n-space-stack-4x">
          <twe-order-details></twe-order-details>
        </div>

        <div class="what-happens-next">
          <div class="what-happens-next__gutter">
            <img
              src="{{ windowService.assetsPath }}assets/svg/calendar-question.svg"
              alt=""
              class="what-happens-next__icon"
            />
          </div>

          <div class="what-happens-next__content">
            <h2 class="twe-text-bold what-happens-next-heading c11n-space-stack-1x">
              {{ content.headings.whatHappensNext }}
            </h2>
            <div *ngIf="!view.isEditCostBasis" class="c11n-space-stack-1x">
              <p class="c11n-text-md twe-text-black c11n-space-stack-1x">
                {{ content.whatHappensNext.transactionConfirmation }}
              </p>
              <p
                class="c11n-text-md twe-text-black c11n-space-stack-1x"
                *ngIf="view.order?.transactionType === transactionTypes.BUY"
              >
                {{ content.whatHappensNext.buy }}
              </p>
              <p
                class="c11n-text-md twe-text-black c11n-space-stack-1x"
                *ngIf="view.order?.transactionType === transactionTypes.SELL"
              >
                {{ content.whatHappensNext.sell }}
              </p>
              <p
                class="c11n-text-md twe-text-black c11n-space-stack-1x"
                *ngIf="view.dollarBasedTradesEnabled && view.isSellingAllHeldShares"
              >
                {{ content.whatHappensNext.sellAll }}
              </p>
              <p
                class="twe-text-bold c11n-text-md twe-text-black c11n-space-stack-1x"
                *ngIf="view.orderHasExceededFundsAvailable"
              >
                {{ content.whatHappensNext?.coverYourTrade?.part1 }}
                <a c11n-link fontWeight="normal" variant="primary-reinforced" [attr.href]="secureSiteUrls.investHub">{{
                  content.whatHappensNext?.coverYourTrade?.part2
                }}</a>
                {{ content.whatHappensNext?.coverYourTrade?.part3 }}
              </p>
            </div>
            <p class="c11n-text-md twe-text-black c11n-space-stack-1x" *ngIf="view.isEditCostBasis">
              {{ content.whatHappensNext.transactionConfirmationEditCostBasis }}
            </p>

            <!-- Static content new -->
            <div *ngIf="!view.isEditCostBasis">
              <p>
                {{ content.whatHappensNext.openOrderStatement.part1 }}
              </p>
              <p>
                {{ content.whatHappensNext.openOrderStatement.part2 }}
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [routerLink]="TRADE_PATH"
                  [queryParams]="{ accountId: view.order.accountId, orderId: view.order.orderId }"
                >
                  {{ content.whatHappensNext.openOrderStatement.part3 }}
                </a>
                <span> {{ content.whatHappensNext.openOrderStatement.part4 }}</span>
                <a
                  c11n-link
                  variant="primary-reinforced"
                  [routerLink]="CANCEL_PATH"
                  [queryParams]="{ accountId: view.order.accountId, orderId: view.order.orderId }"
                >
                  {{ content.whatHappensNext.openOrderStatement.part5 }}
                </a>
                {{ content.whatHappensNext.openOrderStatement.part6 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
