import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { JsonContent } from '@app/etfs-equities/models';
import content from '@content/content.json';

@Component({
  selector: 'twe-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeOut', [
      transition('* => void', [
        style({ opacity: 1, position: 'absolute', top: 0, left: 0 }),
        animate('500ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))])]),
  ],
})
export class RefreshButtonComponent implements OnChanges, OnDestroy {
  @Input()
  isLoading = false;

  @Input()
  isWrap = true;

  @Input()
  title: string = content.loading.refresh;

  @Input()
  ariaLabel = '';

  @Input()
  hasFailed = false;

  @Input()
  alwaysShowContent = false;

  @Output()
  refresh: EventEmitter<void> = new EventEmitter();

  content: JsonContent = content;

  isComplete = false;

  timeoutId: number;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && changes.isLoading.previousValue === true && changes.isLoading.currentValue === false) {
      this.isComplete = true;

      this.timeoutId = window.setTimeout(() => {
        this.isComplete = false;
        this.changeDetector.detectChanges();
      }, 1000);
    }
  }

  emitRefresh() {
    this.refresh.next();
  }

  ngOnDestroy() {
    window.clearTimeout(this.timeoutId);
  }
}
