import { ScreenSize } from '@etfs-equities/store';
import { Action } from '@ngrx/store';

export enum ScreenSizeActionTypes {
  SET_SCREEN_SIZE = '[Screen Size] Set',
}

export class SetScreenSizeAction implements Action {
  readonly type = ScreenSizeActionTypes.SET_SCREEN_SIZE;
  constructor(public payload: ScreenSize) {}
}

export const createSetScreenSizeAction = (screenSize: ScreenSize) => {
  return new SetScreenSizeAction(screenSize);
};

export type ScreenSizeActionsUnion = SetScreenSizeAction;
