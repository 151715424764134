"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FiveTwentyNinePositionStatusCodeEnum = exports.FiveTwentyNinePositionSecurityTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var FiveTwentyNinePositionSecurityTypeEnum;
(function (FiveTwentyNinePositionSecurityTypeEnum) {
  FiveTwentyNinePositionSecurityTypeEnum["MONEY_MARKET_FUND"] = "MONEY_MARKET_FUND";
  FiveTwentyNinePositionSecurityTypeEnum["COMMON_STOCK"] = "COMMON_STOCK";
  FiveTwentyNinePositionSecurityTypeEnum["PREFERRED_STOCK"] = "PREFERRED_STOCK";
  FiveTwentyNinePositionSecurityTypeEnum["CONVERTIBLE_PREFERRED_STOCK"] = "CONVERTIBLE_PREFERRED_STOCK";
  FiveTwentyNinePositionSecurityTypeEnum["RIGHTS"] = "RIGHTS";
  FiveTwentyNinePositionSecurityTypeEnum["WARRANT"] = "WARRANT";
  FiveTwentyNinePositionSecurityTypeEnum["UNITS"] = "UNITS";
  FiveTwentyNinePositionSecurityTypeEnum["MUTUAL_FUND"] = "MUTUAL_FUND";
  FiveTwentyNinePositionSecurityTypeEnum["LIMITED_PARTNERSHIP"] = "LIMITED_PARTNERSHIP";
  FiveTwentyNinePositionSecurityTypeEnum["CALL_OPTION"] = "CALL_OPTION";
  FiveTwentyNinePositionSecurityTypeEnum["PUT_OPTION"] = "PUT_OPTION";
  FiveTwentyNinePositionSecurityTypeEnum["BANK_CALL_OPTION"] = "BANK_CALL_OPTION";
  FiveTwentyNinePositionSecurityTypeEnum["ESCROW"] = "ESCROW";
  FiveTwentyNinePositionSecurityTypeEnum["CORPORATE_BOND"] = "CORPORATE_BOND";
  FiveTwentyNinePositionSecurityTypeEnum["CONVERTIBLE_BOND"] = "CONVERTIBLE_BOND";
  FiveTwentyNinePositionSecurityTypeEnum["MUNICIPAL_BOND"] = "MUNICIPAL_BOND";
  FiveTwentyNinePositionSecurityTypeEnum["SHORT_TERM_PAPER"] = "SHORT_TERM_PAPER";
  FiveTwentyNinePositionSecurityTypeEnum["BOND_UNIT"] = "BOND_UNIT";
  FiveTwentyNinePositionSecurityTypeEnum["MUNI_ASSESSMENT_DIST"] = "MUNI_ASSESSMENT_DIST";
  FiveTwentyNinePositionSecurityTypeEnum["US_TREASURY_BILL"] = "US_TREASURY_BILL";
  FiveTwentyNinePositionSecurityTypeEnum["US_TREASURY_NOTE"] = "US_TREASURY_NOTE";
  FiveTwentyNinePositionSecurityTypeEnum["US_TREASURY_BOND"] = "US_TREASURY_BOND";
  FiveTwentyNinePositionSecurityTypeEnum["OTHER_GOVERNMENT"] = "OTHER_GOVERNMENT";
  FiveTwentyNinePositionSecurityTypeEnum["US_TREASURY_ZERO_COUPON"] = "US_TREASURY_ZERO_COUPON";
  FiveTwentyNinePositionSecurityTypeEnum["GOVERNMENT_MORTGAGE"] = "GOVERNMENT_MORTGAGE";
  FiveTwentyNinePositionSecurityTypeEnum["COLLATERALIZED_MORTGAGE_OBLIGATION"] = "COLLATERALIZED_MORTGAGE_OBLIGATION";
  FiveTwentyNinePositionSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_EQUITY"] = "WHEN_AS_AND_IF_ISSUED_EQUITY";
  FiveTwentyNinePositionSecurityTypeEnum["WHEN_AS_AND_IF_ISSUED_BOND"] = "WHEN_AS_AND_IF_ISSUED_BOND";
  FiveTwentyNinePositionSecurityTypeEnum["BANKERS_ACCEPTANCE"] = "BANKERS_ACCEPTANCE";
  FiveTwentyNinePositionSecurityTypeEnum["CERTIFICATES_OF_DEPOSIT"] = "CERTIFICATES_OF_DEPOSIT";
  FiveTwentyNinePositionSecurityTypeEnum["COMMERCIAL_PAPER"] = "COMMERCIAL_PAPER";
  FiveTwentyNinePositionSecurityTypeEnum["FIXED_INCOME_UNITS"] = "FIXED_INCOME_UNITS";
  FiveTwentyNinePositionSecurityTypeEnum["ETF"] = "ETF";
  FiveTwentyNinePositionSecurityTypeEnum["VANGUARD_ETF"] = "VANGUARD_ETF";
  FiveTwentyNinePositionSecurityTypeEnum["MISCELLANEOUS_FIXED_INCOME"] = "MISCELLANEOUS_FIXED_INCOME";
  FiveTwentyNinePositionSecurityTypeEnum["MISCELLANEOUS_STOCK"] = "MISCELLANEOUS_STOCK";
  FiveTwentyNinePositionSecurityTypeEnum["TAX_SHELTER"] = "TAX_SHELTER";
  FiveTwentyNinePositionSecurityTypeEnum["UNKNOWN"] = "UNKNOWN";
})(FiveTwentyNinePositionSecurityTypeEnum = exports.FiveTwentyNinePositionSecurityTypeEnum || (exports.FiveTwentyNinePositionSecurityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FiveTwentyNinePositionStatusCodeEnum;
(function (FiveTwentyNinePositionStatusCodeEnum) {
  FiveTwentyNinePositionStatusCodeEnum["CLOSED_02"] = "CLOSED_02";
  FiveTwentyNinePositionStatusCodeEnum["ESCHEATED_05"] = "ESCHEATED_05";
  FiveTwentyNinePositionStatusCodeEnum["MARGINAL_03"] = "MARGINAL_03";
  FiveTwentyNinePositionStatusCodeEnum["OPEN_01"] = "OPEN_01";
  FiveTwentyNinePositionStatusCodeEnum["RPO_04"] = "RPO_04";
  FiveTwentyNinePositionStatusCodeEnum["UNKNOWN"] = "UNKNOWN";
})(FiveTwentyNinePositionStatusCodeEnum = exports.FiveTwentyNinePositionStatusCodeEnum || (exports.FiveTwentyNinePositionStatusCodeEnum = {}));
