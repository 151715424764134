"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum;
(function (HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum) {
  HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum["FIFO"] = "FIFO";
  HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum["MINT"] = "MINT";
  HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum["SPEC"] = "SPEC";
  HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum["AVGC"] = "AVGC";
  HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum["MAXT"] = "MAXT";
  HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum["HIFO"] = "HIFO";
})(HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum = exports.HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum || (exports.HoldingCostBasisInfoUpdatedCostBasisMethodCodeEnum = {}));
