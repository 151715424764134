import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TooltipDirection } from '@vg-constellation/angular-17/tooltip';

@Component({
  selector: 'twe-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TooltipComponent {
  @Input()
  labelText = '';

  @Input()
  direction: TooltipDirection = 'right';

  @Input()
  tooltipHeader: string;

  @Input()
  autoOrientation = true;
}
