"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeeFeeTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var FeeFeeTypeEnum;
(function (FeeFeeTypeEnum) {
  FeeFeeTypeEnum["PURCHASE_FEE"] = "PURCHASE_FEE";
  FeeFeeTypeEnum["REDEMPTION_FEE"] = "REDEMPTION_FEE";
})(FeeFeeTypeEnum = exports.FeeFeeTypeEnum || (exports.FeeFeeTypeEnum = {}));
