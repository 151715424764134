<twe-triggered-rules-modal
  [acceptedRulesNextStep]="AcceptedRulesNextStep.PREVIEW"
  (openOrderTypesModalEmitter)="orderTypesModal.open()"
></twe-triggered-rules-modal>

<twe-generic-error-modal
  [isChangeOrder]="isChangeOrder"
  (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"
></twe-generic-error-modal>

<twe-order-types-modal #orderTypesModal></twe-order-types-modal>

<ng-container
  *ngIf="{
    accounts: (accounts$ | async),
    selectedAccount: (selectedAccount$ | async),
    quote: (quote$ | async),
    quoteIsVgEtf: (quoteIsVgEtf$ | async),
    env: (env$ | async),
    volatilityBannerEnabled: (volatilityBannerEnabled$ | async),
    dollarBasedTradesEnabled: (dollarBasedTradesEnabled$ | async),
    sharesHeld: (sharesHeld$ | async),
    isSellingAllHeldShares: (isSellingAllHeldShares$ | async),
    isIncapacitated: (isIncapacitated$ | async),
    order: (order$ | async),
    selectedAction: (selectedAction$ | async),
    accountIsMargin: (accountIsMargin$ | async),
    actionIsBuyToCoverOrSellShort: (actionIsBuyToCoverOrSellShort$ | async),
    hasEnoughCashMarginShares: (hasEnoughCashMarginShares$ | async),
    isAnnouncementBannerEnabled: (isAnnouncementBannerEnabled$ | async),
    selectShowNoBuyOrdersBannerForLowTierOtc: (selectShowNoBuyOrdersBannerForLowTierOtc$ | async),
    orderIsEveningDuration: (orderIsEveningDuration$ | async)
  } as view"
>
  <div *ngIf="view.isIncapacitated; else tradeTicketForm" class="row twe-error-page">
    <twe-service-unavailable-msg class="col-10" iconType="warning">
      <p>{{ content.incapacitatedShingle.title }}</p>
      <p>
        {{ content.incapacitatedShingle.body1 }}
        <a c11n-link variant="primary-reinforced" [attr.href]="content.incapacitatedShingle.phoneNumberVBSLink">{{
          content.incapacitatedShingle.phoneNumberVBS
        }}</a>
        {{ content.incapacitatedShingle.body2 }}
      </p>
      <p>
        {{ content.incapacitatedShingle.body3 }}
        <a
          c11n-link
          variant="primary-reinforced"
          [attr.href]="content.incapacitatedShingle.phoneNumberTTYLink"
          (click)="adobeService.sendAdobeTrackingOnClick('TTY/TTD Phone number', 'link', 'incapacitated')"
          >{{ content.incapacitatedShingle.phoneNumberTTY }}</a
        >
      </p>
      <p>
        <a c11n-link variant="primary-independent" attr.href="{{ view.env?.origin_personal }}/us/MyHome">
          {{ content.incapacitatedShingle.overviewLink }}
        </a>
      </p>
    </twe-service-unavailable-msg>
  </div>
  <ng-template #tradeTicketForm>
    <twe-critical-error *ngIf="changeOrderHasCriticalOrderDetailsError">
      <p
        class="twe-c11n-color-ink-400 c11n-space-stack-3x"
        [innerHTML]="content.changeOrder.requestCannotBeCompleted"
      ></p>
      <p class="twe-c11n-color-ink-400 c11n-space-stack-0_5x" [innerHTML]="content.incapacitatedShingle.body3"></p>
      <p
        class="twe-c11n-color-ink-400 c11n-space-stack-3x"
        [innerHTML]="content.contactUsModal.phoneNumberTTY + '.'"
      ></p>
    </twe-critical-error>

    <form [formGroup]="formGroupName" *ngIf="!changeOrderHasCriticalOrderDetailsError">
      <h2 class="visually-hidden">{{ content?.tradeOrderForm }}</h2>
      <div class="container-fluid">
        <twe-volatility-banner *ngIf="view.volatilityBannerEnabled && !changeOrderIsLoading"></twe-volatility-banner>

        <tcx-announcement-banner
          *ngIf="isAnnouncementBannerVisible(view.isAnnouncementBannerEnabled) && !changeOrderIsLoading"
          class="d-block mb-4"
          [content]="announcementBannerContent"
          [iconName]="'announcement'"
          [closeAriaLabel]="content?.announcementBanner.closeButtonText"
          (onClose)="closeAnnouncementBanner()"
        ></tcx-announcement-banner>

        <!-- Accounts Selector -->
        <div class="row">
          <twe-accounts-control
            class="col-12"
            [isChangeOrder]="isChangeOrder"
            (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"
            [showAccountDetails]="true"
          ></twe-accounts-control>
        </div>

        <div class="c11n-space-stack-3x" *ngIf="view.orderIsEveningDuration && isChangeOrder">
          <twe-extended-hours-banner></twe-extended-hours-banner>
        </div>

        <twe-accounts-error [showAsPage]="false"></twe-accounts-error>

        <!-- dividing line between account selector and 2-column trade form -->
        <div class="col-12 c11n-space-stack-4x twe-border-top"></div>

        <div class="row c11n-space-stack-5x c11n-space--force">
          <div class="col-lg-6 twe-column-left">
            <div class="c11n-space-stack-3x" *ngIf="isChangeOrder">
              <twe-change-order-detail
                [order]="view.order"
                [title]="content?.changeOrder.header"
                [isChangeOrder]="isChangeOrder"
              ></twe-change-order-detail>
            </div>

            <div class="c11n-space-stack-3x" *ngIf="!isChangeOrder">
              <twe-trade-symbol-keyword
                [isChangeOrder]="isChangeOrder"
                [parentGroup]="formGroupName"
              ></twe-trade-symbol-keyword>
            </div>

            <twe-quote class="d-block d-lg-none c11n-space-stack-3x" [isChangeOrder]="isChangeOrder"></twe-quote>

            <twe-transaction-type-control *ngIf="!isChangeOrder"></twe-transaction-type-control>

            <div *ngIf="!view.selectShowNoBuyOrdersBannerForLowTierOtc">
              <!-- Security Account Type control -->
              <twe-security-account-type-control
                [hidden]="!(view.accountIsMargin && view.quote && view.selectedAction)"
              ></twe-security-account-type-control>
            </div>

            <!-- Symbol / Shares inputs -->
            <div class="c11n-space-stack-3x">
              <!-- Shares input -->
              <div class="c11n-space-stack-1x">
                <twe-shares-control
                  [parentGroup]="formGroupName"
                  [isChangeOrder]="isChangeOrder"
                  (amountEvent)="amountOnBlur$.next()"
                ></twe-shares-control>
              </div>
              <c11n-checkbox
                #sellAllCheckbox
                *ngIf="
                  view.dollarBasedTradesEnabled && view.quoteIsVgEtf && view.sharesHeld > 0 && view.selectedAccount
                "
                [hidden]="!tradeTicketService.orderActionIsSell()"
                class="sell-all-checkbox"
                [labelText]="content.labels.sellAllShares"
              >
                <input
                  c11nCheckboxInput
                  id="sellAllCheckbox"
                  type="checkbox"
                  name="sellAllCheckbox"
                  [formControl]="tradeTicketService?.tradeTicket?.controls.sellAll"
                />
                <div
                  aria-live="polite"
                  [attr.aria-busy]="tradeTicketService?.tradeTicket?.controls.sellAll.value === null ? true : false"
                >
                  <span *ngIf="tradeTicketService?.isSellAllChecked()" class="visually-hidden">
                    {{ autoSelectSellAll ? content.labels.sellAllSharesText : '' }}
                    {{ content.labels.sellAllSharesHeld }}
                    {{ view.sharesHeld }}
                    {{ content.labels.shares }}
                  </span>
                  <span *ngIf="!tradeTicketService?.isSellAllChecked()" class="visually-hidden">
                    {{ content.labels.sellAllSharesCleared }}
                  </span>
                </div>
              </c11n-checkbox>
            </div>

            <div
              *ngIf="
                view.isSellingAllHeldShares &&
                view.sharesHeld % 1 !== 0 &&
                tradeTicketService?.tradeTicket?.controls?.amount?.valid
              "
              class="c11n-space-stack-3x"
            >
              <c11n-banner [headingText]="content.labels.important" variant="warning">
                <div class="c11n-text-md--crop">
                  {{ content.sellAllInContextMessage }}
                </div>
              </c11n-banner>
            </div>
            <div *ngIf="!view.selectShowNoBuyOrdersBannerForLowTierOtc">
              <twe-order-type-control *ngIf="tradeTicketService?.orderTypeIsVisible()"></twe-order-type-control>

              <!-- stop price, limit price, and duration -->
              <div
                class="row c11n-space-stack-3x"
                [ngClass]="{
                  'mb-0':
                    tradeTicketService?.orderTypeIsMarket() ||
                    (!tradeTicketService?.stopPriceIsVisible() && !tradeTicketService?.limitPriceIsVisible())
                }"
              >
                <twe-price-control
                  *ngIf="tradeTicketService?.stopPriceIsVisible()"
                  [className]="
                    tradeTicketService?.orderTypeIsStopLimit() ? 'col-sm-6 stop-price-margin-mobile' : 'col-lg-12'
                  "
                  [controlName]="'stopPrice'"
                  [controlLabel]="content?.labels.stopPrice"
                  [parentGroup]="formGroupName"
                  [hasError]="tradeTicketService?.stopPriceHasError()"
                  [requiredWhenInError]="tradeTicketService?.stopPriceHasRequiredWhenInError()"
                >
                </twe-price-control>

                <twe-price-control
                  *ngIf="tradeTicketService?.limitPriceIsVisible()"
                  [className]="tradeTicketService?.orderTypeIsStopLimit() ? 'col-sm-6' : 'col-lg-12'"
                  [controlName]="'limitPrice'"
                  [controlLabel]="content?.labels.limitPrice"
                  [parentGroup]="formGroupName"
                  [hasError]="tradeTicketService?.limitPriceHasError()"
                  [requiredWhenInError]="tradeTicketService?.limitPriceHasRequiredWhenInError()"
                >
                </twe-price-control>
              </div>
              <div
                *ngIf="tradeTicketService?.durationIsVisible()"
                class="c11n-space-stack-3x"
                [ngClass]="{ 'twe-mt-n075': tradeTicketService?.orderTypeIsMarket() }"
              >
                <twe-duration-control [inEveningOrderFlow]="view.orderIsEveningDuration && isChangeOrder">
                </twe-duration-control>
              </div>
              <twe-cost-basis-control
                class="d-block c11n-space-stack-3x"
                *ngIf="tradeTicketService?.costBasisIsVisible"
                [isChangeOrder]="isChangeOrder"
              ></twe-cost-basis-control>
              <twe-all-or-none-control
                class="d-block c11n-space-stack-3x c11n-space--force"
                [hidden]="!tradeTicketService?.allOrNoneIsVisible()"
              ></twe-all-or-none-control>
            </div>
            <div class="c11n-space-stack-3x">
              <twe-order-summary></twe-order-summary>
            </div>

            <div aria-live="polite">
              <twe-order-validation-banner></twe-order-validation-banner>
              <twe-margin-requirements-vg-etfs-banner></twe-margin-requirements-vg-etfs-banner>
            </div>

            <twe-trade-buttons
              (preview)="validate()"
              (clear)="resetTradeTicket(); adobeService.sendAdobeLaunchProcess('equityETFTicket-capture: Clear ticket')"
              [isChangeOrder]="isChangeOrder"
            ></twe-trade-buttons>
          </div>

          <div class="col-lg-6 twe-column-right">
            <twe-quote class="d-none d-lg-block c11n-space-stack-3x" [isChangeOrder]="isChangeOrder"></twe-quote>
          </div>
        </div>

        <div class="c11n-text-sm twe-c11n-color-ink-400 pt-lg-2 c11n-space-stack-2x c11n-space--force">{{
          content.footnote
        }}</div>
      </div>
    </form>

    <!-- No changes Error Modal -->
    <twe-no-changes-error-modal></twe-no-changes-error-modal>

    <!-- Cannot modify Modal -->
    <twe-cannot-modify-modal
      [showModal]="!changeOrderIsLoading && !orderIsModifiable && !changeOrderHasCriticalOrderDetailsError"
    >
    </twe-cannot-modify-modal>
  </ng-template>
</ng-container>
