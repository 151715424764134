import { Component, Input, OnInit } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import content from '@content/content.json';
import { Account, JsonContent } from '@etfs-equities/models'; // Import Account here
import { selectSelectedAccount, TayneState } from '@etfs-equities/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'twe-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss'],
})
export class AlertInfoComponent implements OnInit {
  content: JsonContent = content;

  @Input()
  heading: string;

  @Input()
  bodyText: string[];

  crewDisclaimerMFEEnabled = false;
  selectedAccount$: Observable<Account.Account>;

  constructor(private gatekeeperService: GatekeeperService, private store: Store<TayneState>) {}

  ngOnInit(): void {
    this.gatekeeperService
      .checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CREW_DISCLAIMER_MFE)
      .subscribe((isEnabled) => {
        this.crewDisclaimerMFEEnabled = isEnabled;
      });
    this.selectedAccount$ = this.store.pipe(select(selectSelectedAccount));
  }
}
