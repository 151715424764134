"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RetirementContributionInstructionsContributionSourceEnum = exports.RetirementContributionInstructionsContributionTypeEnum = exports.RetirementContributionInstructionsTaxYearEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var RetirementContributionInstructionsTaxYearEnum;
(function (RetirementContributionInstructionsTaxYearEnum) {
  RetirementContributionInstructionsTaxYearEnum["PRIOR"] = "PRIOR";
  RetirementContributionInstructionsTaxYearEnum["CURRENT"] = "CURRENT";
})(RetirementContributionInstructionsTaxYearEnum = exports.RetirementContributionInstructionsTaxYearEnum || (exports.RetirementContributionInstructionsTaxYearEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RetirementContributionInstructionsContributionTypeEnum;
(function (RetirementContributionInstructionsContributionTypeEnum) {
  RetirementContributionInstructionsContributionTypeEnum["CONTRIBUTION"] = "CONTRIBUTION";
  RetirementContributionInstructionsContributionTypeEnum["ROLLOVER"] = "ROLLOVER";
  RetirementContributionInstructionsContributionTypeEnum["CONVERSION"] = "CONVERSION";
})(RetirementContributionInstructionsContributionTypeEnum = exports.RetirementContributionInstructionsContributionTypeEnum || (exports.RetirementContributionInstructionsContributionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var RetirementContributionInstructionsContributionSourceEnum;
(function (RetirementContributionInstructionsContributionSourceEnum) {
  RetirementContributionInstructionsContributionSourceEnum["EMPLOYER"] = "EMPLOYER";
  RetirementContributionInstructionsContributionSourceEnum["INDIVIDUAL"] = "INDIVIDUAL";
})(RetirementContributionInstructionsContributionSourceEnum = exports.RetirementContributionInstructionsContributionSourceEnum || (exports.RetirementContributionInstructionsContributionSourceEnum = {}));
