"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeOrderSecurityInstructionSecurityAccountTypeEnum = exports.ChangeOrderSecurityInstructionSecurityIdTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ChangeOrderSecurityInstructionSecurityIdTypeEnum;
(function (ChangeOrderSecurityInstructionSecurityIdTypeEnum) {
  ChangeOrderSecurityInstructionSecurityIdTypeEnum["CUSIP"] = "CUSIP";
  ChangeOrderSecurityInstructionSecurityIdTypeEnum["TICKER"] = "TICKER";
})(ChangeOrderSecurityInstructionSecurityIdTypeEnum = exports.ChangeOrderSecurityInstructionSecurityIdTypeEnum || (exports.ChangeOrderSecurityInstructionSecurityIdTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ChangeOrderSecurityInstructionSecurityAccountTypeEnum;
(function (ChangeOrderSecurityInstructionSecurityAccountTypeEnum) {
  ChangeOrderSecurityInstructionSecurityAccountTypeEnum["CASH"] = "CASH";
  ChangeOrderSecurityInstructionSecurityAccountTypeEnum["MARGIN"] = "MARGIN";
  ChangeOrderSecurityInstructionSecurityAccountTypeEnum["SHORT"] = "SHORT";
  ChangeOrderSecurityInstructionSecurityAccountTypeEnum["WHEN_ISSUED"] = "WHEN_ISSUED";
})(ChangeOrderSecurityInstructionSecurityAccountTypeEnum = exports.ChangeOrderSecurityInstructionSecurityAccountTypeEnum || (exports.ChangeOrderSecurityInstructionSecurityAccountTypeEnum = {}));
