<ng-container
  *ngIf="{
    accounts: (accounts$ | async),
    selectedAccount: (selectedAccount$ | async)
  } as view"
>
  <div class="account-selector" *ngIf="!hasAccountRetrievalError">
    <ng-container *ngIf="view.selectedAccount?.accountName && isChangeOrder">
      <span class="d-block c11n-text-xs c11n-space-stack-0_5x">{{ content.labels.account }}</span>
      <account-selector
        [accountSelectorConfigProvider]="accountSelectorConfigProvider"
        [brokerageAccountProvider]="selectedAccountProvider"
        [formControlProvider]="formControlProvider"
        [accountSelectorControlPlaneProvider]="accountSelectorControlPlaneProvider"
      ></account-selector>
    </ng-container>

    <div *ngIf="!isChangeOrder" data-testid="account-selector">
      <label c11n-label for="account-selector">{{ content.labels.account }}</label>
      <account-selector
        [accountSelectorConfigProvider]="accountSelectorConfigProvider"
        [brokerageAccountProvider]="brokerageAccountProvider"
        [formControlProvider]="formControlProvider"
        [accountSelectorControlPlaneProvider]="accountSelectorControlPlaneProvider"
        [trackingEventHandler]="accountSelectorTrackingEvent"
      ></account-selector>
    </div>
  </div>
</ng-container>
