"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExchangeTradingInstructionsOrderCategory = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var ExchangeTradingInstructionsOrderCategory;
(function (ExchangeTradingInstructionsOrderCategory) {
  ExchangeTradingInstructionsOrderCategory["MARKET"] = "MARKET";
  ExchangeTradingInstructionsOrderCategory["LIMIT"] = "LIMIT";
  ExchangeTradingInstructionsOrderCategory["STOP"] = "STOP";
  ExchangeTradingInstructionsOrderCategory["OR_BETTER"] = "OR_BETTER";
  ExchangeTradingInstructionsOrderCategory["STOP_LIMIT"] = "STOP_LIMIT";
  ExchangeTradingInstructionsOrderCategory["CLOSED"] = "CLOSED";
  ExchangeTradingInstructionsOrderCategory["WITH_OR_WITHOUT"] = "WITH_OR_WITHOUT";
  ExchangeTradingInstructionsOrderCategory["MARKET_ON_CLOSE"] = "MARKET_ON_CLOSE";
})(ExchangeTradingInstructionsOrderCategory = exports.ExchangeTradingInstructionsOrderCategory || (exports.ExchangeTradingInstructionsOrderCategory = {}));
