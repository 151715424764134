"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FundProfileInitialPurchaseEnum = exports.FundProfileAdditionalPurchaseEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var FundProfileAdditionalPurchaseEnum;
(function (FundProfileAdditionalPurchaseEnum) {
  FundProfileAdditionalPurchaseEnum["AVAILABLE"] = "Available";
  FundProfileAdditionalPurchaseEnum["NOT_AVAILABLE"] = "Not Available";
})(FundProfileAdditionalPurchaseEnum = exports.FundProfileAdditionalPurchaseEnum || (exports.FundProfileAdditionalPurchaseEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FundProfileInitialPurchaseEnum;
(function (FundProfileInitialPurchaseEnum) {
  FundProfileInitialPurchaseEnum["AVAILABLE"] = "Available";
  FundProfileInitialPurchaseEnum["NOT_AVAILABLE"] = "Not Available";
})(FundProfileInitialPurchaseEnum = exports.FundProfileInitialPurchaseEnum || (exports.FundProfileInitialPurchaseEnum = {}));
