"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientFlagsResponseClientTypeEnum = exports.ClientFlagsResponseRmaStatusEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var ClientFlagsResponseRmaStatusEnum;
(function (ClientFlagsResponseRmaStatusEnum) {
  ClientFlagsResponseRmaStatusEnum["INITIATED"] = "INITIATED";
  ClientFlagsResponseRmaStatusEnum["PENDING"] = "PENDING";
  ClientFlagsResponseRmaStatusEnum["ACCEPTED"] = "ACCEPTED";
  ClientFlagsResponseRmaStatusEnum["TERMINATED"] = "TERMINATED";
  ClientFlagsResponseRmaStatusEnum["SEND"] = "SEND";
  ClientFlagsResponseRmaStatusEnum["SECONDARY_OWNER"] = "SECONDARY_OWNER";
})(ClientFlagsResponseRmaStatusEnum = exports.ClientFlagsResponseRmaStatusEnum || (exports.ClientFlagsResponseRmaStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
var ClientFlagsResponseClientTypeEnum;
(function (ClientFlagsResponseClientTypeEnum) {
  ClientFlagsResponseClientTypeEnum["P"] = "P";
  ClientFlagsResponseClientTypeEnum["O"] = "O";
})(ClientFlagsResponseClientTypeEnum = exports.ClientFlagsResponseClientTypeEnum || (exports.ClientFlagsResponseClientTypeEnum = {}));
