import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'twe-open-order-item-cell',
  standalone: true,
  imports: [NgIf],
  templateUrl: './open-order-item-cell.component.html',
  styleUrl: './open-order-item-cell.component.scss',
})
export class OpenOrderItemCellComponent {
  @Input() label: string;
  @Input() value: string;
  @Input() subValue?: string;
  @Input() secondSubValue?: string;
}
