"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SecurityDetailsSecurityTypeEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var SecurityDetailsSecurityTypeEnum;
(function (SecurityDetailsSecurityTypeEnum) {
  SecurityDetailsSecurityTypeEnum["COMMON_STOCK"] = "COMMON_STOCK";
  SecurityDetailsSecurityTypeEnum["PREFERRED_STOCK"] = "PREFERRED_STOCK";
  SecurityDetailsSecurityTypeEnum["CONVERTIBLE_PREFERRED_STOCK"] = "CONVERTIBLE_PREFERRED_STOCK";
  SecurityDetailsSecurityTypeEnum["RIGHTS"] = "RIGHTS";
  SecurityDetailsSecurityTypeEnum["WARRANT"] = "WARRANT";
  SecurityDetailsSecurityTypeEnum["UNITS"] = "UNITS";
  SecurityDetailsSecurityTypeEnum["NON_VANGUARD_MUTUAL_FUND"] = "NON_VANGUARD_MUTUAL_FUND";
  SecurityDetailsSecurityTypeEnum["VANGUARD_MUTUAL_FUND"] = "VANGUARD_MUTUAL_FUND";
  SecurityDetailsSecurityTypeEnum["CALL_OPTION"] = "CALL_OPTION";
  SecurityDetailsSecurityTypeEnum["PUT_OPTION"] = "PUT_OPTION";
  SecurityDetailsSecurityTypeEnum["CORPORATE_BOND"] = "CORPORATE_BOND";
  SecurityDetailsSecurityTypeEnum["CONVERTIBLE_BOND"] = "CONVERTIBLE_BOND";
  SecurityDetailsSecurityTypeEnum["MUNICIPAL_BOND"] = "MUNICIPAL_BOND";
  SecurityDetailsSecurityTypeEnum["US_TREASURY_BILL"] = "US_TREASURY_BILL";
  SecurityDetailsSecurityTypeEnum["US_TREASURY_NOTE"] = "US_TREASURY_NOTE";
  SecurityDetailsSecurityTypeEnum["US_TREASURY_BOND"] = "US_TREASURY_BOND";
  SecurityDetailsSecurityTypeEnum["OTHER_GOVERNMENT"] = "OTHER_GOVERNMENT";
  SecurityDetailsSecurityTypeEnum["US_TREASURY_ZERO_COUPON"] = "US_TREASURY_ZERO_COUPON";
  SecurityDetailsSecurityTypeEnum["GOVERNMENT_MORTGAGE"] = "GOVERNMENT_MORTGAGE";
  SecurityDetailsSecurityTypeEnum["CERTIFICATES_OF_DEPOSIT"] = "CERTIFICATES_OF_DEPOSIT";
  SecurityDetailsSecurityTypeEnum["NON_VANGUARD_ETF"] = "NON_VANGUARD_ETF";
  SecurityDetailsSecurityTypeEnum["VANGUARD_ETF"] = "VANGUARD_ETF";
  SecurityDetailsSecurityTypeEnum["MISCELLANEOUS_FIXED_INCOME"] = "MISCELLANEOUS_FIXED_INCOME";
})(SecurityDetailsSecurityTypeEnum = exports.SecurityDetailsSecurityTypeEnum || (exports.SecurityDetailsSecurityTypeEnum = {}));
