import { createSelector } from '@ngrx/store';

import { ScreenSize, ScreenSizeState, TayneState } from '../../states';
import { selectTayneState } from '../tayne/tayne.selectors';

export const selectScreenSize = createSelector(selectTayneState, (state: TayneState) => state.screenSize);

export const selectIsScreenXXLarge = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state.screenSize === ScreenSize.XXL
);

export const selectIsScreenXLarge = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state.screenSize === ScreenSize.XL
);

export const selectIsScreenLarge = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state.screenSize === ScreenSize.LG
);

export const selectIsScreenMedium = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state.screenSize === ScreenSize.MD
);

export const selectIsScreenSmall = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state.screenSize === ScreenSize.SM
);

export const selectIsScreenXSmall = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) => state.screenSize === ScreenSize.XS
);

// combinator selectors
export const selectIsScreenMediumOrLess = createSelector(
  selectScreenSize,
  (state: ScreenSizeState) =>
    state.screenSize === ScreenSize.XS || state.screenSize === ScreenSize.SM || state.screenSize === ScreenSize.MD
);
