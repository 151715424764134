"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuyTransactionDestinationCostBasisMethod = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var BuyTransactionDestinationCostBasisMethod;
(function (BuyTransactionDestinationCostBasisMethod) {
  BuyTransactionDestinationCostBasisMethod["FIRST_IN_FIRST_OUT"] = "FIRST_IN_FIRST_OUT";
  BuyTransactionDestinationCostBasisMethod["AVERAGE_COST"] = "AVERAGE_COST";
  BuyTransactionDestinationCostBasisMethod["SPECIFIC_ID"] = "SPECIFIC_ID";
  BuyTransactionDestinationCostBasisMethod["MAXIMUM_GAIN"] = "MAXIMUM_GAIN";
  BuyTransactionDestinationCostBasisMethod["HIGHEST_IN_FIRST_OUT"] = "HIGHEST_IN_FIRST_OUT";
  BuyTransactionDestinationCostBasisMethod["MINIMUM_TAX"] = "MINIMUM_TAX";
})(BuyTransactionDestinationCostBasisMethod = exports.BuyTransactionDestinationCostBasisMethod || (exports.BuyTransactionDestinationCostBasisMethod = {}));
