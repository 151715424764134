<div
  class="container-fluid"
  *ngIf="{
    order: (order$ | async),
    quote: (quote$ | async),
    userHistoryUrl: (userHistoryUrl$ | async)
  } as view"
>
  <twe-service-unavailable-msg *ngIf="isOrderDetailsError" iconType="warning">
    <p class="twe-c11n-color-ink-400 c11n-space-stack-0_5x" [innerHTML]="content?.editCostBasis.orderDetailsErrorMsg">
    </p>
    <p class="mt-4">
      <a
        c11n-link
        tabindex="0"
        (click)="handleBackClick(view.userHistoryUrl)"
        (keydown.enter)="handleBackClick(view.userHistoryUrl)"
      >
        {{ content?.labels.goBack }}
      </a>
    </p>
  </twe-service-unavailable-msg>

  <div class="row c11n-space-stack-5x c11n-space--force" *ngIf="!isOrderDetailsError">
    <twe-accounts-control
      class="col-xl-9 col-md-12"
      [isChangeOrder]="isChangeOrder"
      [showAccountDetails]="true"
      (hasAccountRetrievalErrorEmitter)="setHasAccountRetrievalError($event)"
    ></twe-accounts-control>

    <twe-accounts-error [showAsPage]="false"></twe-accounts-error>

    <!-- dividing line between selected account and order details -->
    <div class="col-12 c11n-space-stack-4x twe-border-top"></div>

    <div class="col-lg-6 twe-column-left">
      <div class="c11n-space-stack-2x d-block">
        <twe-change-order-detail [title]="content?.editCostBasis.title" [order]="view.order"></twe-change-order-detail>
      </div>
      <div class="c11n-space-stack-3x">
        <twe-cost-basis-control [isChangeOrder]="isChangeOrder"></twe-cost-basis-control>
      </div>

      <twe-executed-price class="d-block d-lg-none c11n-space-stack-3x"></twe-executed-price>

      <div class="c11n-space-stack-3x">
        <twe-ecb-order-summary> </twe-ecb-order-summary>
      </div>

      <twe-trade-buttons (preview)="validateCostBasis()" [isChangeOrder]="isChangeOrder"></twe-trade-buttons>
    </div>

    <div class="col-lg-6 twe-column-right">
      <twe-executed-price class="d-none d-lg-block c11n-space-stack-3x"></twe-executed-price>
    </div>
  </div>
</div>
<twe-no-changes-error-modal></twe-no-changes-error-modal>
