import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GatekeeperFeatureIds } from '@app/core/enums/gatekeeper-features.enum';
import { GatekeeperService } from '@app/core/services';
import { ApiResponse, CrewDisclaimer } from '@app/etfs-equities/models';
import { EnvironmentService } from '@shared/services/environment/environment.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CrewDisclaimerService {
  //  Public variables...
  showCrewDisclaimer = false;

  constructor(
    private readonly http: HttpClient,
    private readonly envService: EnvironmentService,
    private readonly gatekeeperService: GatekeeperService
  ) {}

  fetchCrewDisclaimerFlag(accountId: number): Observable<ApiResponse<CrewDisclaimer>> {
    return this.gatekeeperService.checkSingleFeatureStatus(GatekeeperFeatureIds.TWE_CREW_DISCLAIMER_MFE).pipe(
      switchMap((isEnabled) => {
        if (isEnabled) {
          this.showCrewDisclaimer = true;
          return of();
        } else {
          return this.http
            .get<ApiResponse<CrewDisclaimer>>(
              this.envService.getApiUrlBaseOnRoute() + `api/crew-disclaimer?accountId=${accountId}`,
              { withCredentials: true }
            )
            .pipe(
              tap((response) => (this.showCrewDisclaimer = response.data.crewCanTransact)),
              catchError((error: HttpErrorResponse) => this.handleHttpError(error))
            );
        }
      })
    );
  }

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    return throwError(() => error);
  }
}
