"use strict";

/* tslint:disable */
/* eslint-disable */
/**
 * VGA Invest Application Programming Interface
 * Shared service layer for Invest-family labs
 *
 * The version of the OpenAPI document: 2.43.0
 * Contact: InvestAPI@vanguard.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TriggeredTradingRulesTransactionLevelEnum = exports.TriggeredTradingRulesRuleActionEnum = void 0;
/**
    * @export
    * @enum {string}
    */
var TriggeredTradingRulesRuleActionEnum;
(function (TriggeredTradingRulesRuleActionEnum) {
  TriggeredTradingRulesRuleActionEnum["WARNING"] = "WARNING";
  TriggeredTradingRulesRuleActionEnum["ERROR"] = "ERROR";
  TriggeredTradingRulesRuleActionEnum["REVIEW_RELEASE"] = "REVIEW_RELEASE";
})(TriggeredTradingRulesRuleActionEnum = exports.TriggeredTradingRulesRuleActionEnum || (exports.TriggeredTradingRulesRuleActionEnum = {}));
/**
    * @export
    * @enum {string}
    */
var TriggeredTradingRulesTransactionLevelEnum;
(function (TriggeredTradingRulesTransactionLevelEnum) {
  TriggeredTradingRulesTransactionLevelEnum["ACCOUNT_LEVEL"] = "ACCOUNT_LEVEL";
  TriggeredTradingRulesTransactionLevelEnum["TRANSACTION_DETAIL_LEVEL"] = "TRANSACTION_DETAIL_LEVEL";
  TriggeredTradingRulesTransactionLevelEnum["HOLDING_LEVEL"] = "HOLDING_LEVEL";
  TriggeredTradingRulesTransactionLevelEnum["CLIENT_LEVEL"] = "CLIENT_LEVEL";
})(TriggeredTradingRulesTransactionLevelEnum = exports.TriggeredTradingRulesTransactionLevelEnum || (exports.TriggeredTradingRulesTransactionLevelEnum = {}));
